import { createAction, props, Action } from '@ngrx/store';
import { EmailAssistance, ItemAllAides, TextFormation } from './ui.reducer';

export class SOUSMENU {
  public libelle: string;
  public lienActif: boolean;
  public actif: boolean;
  public ACTUS = 4;
  public LM = 5;
}

export const confirmationDialog = createAction(
  '[UI] ConfirmationDialog',
  props<{
    message: string;
    titre: string;
    confirmAction: Action;
    cancelAction: Action;
  }>()
);

export const errorMessage = createAction(
  '[UI] ErrorMessage',
  props<{ errorMessage: string; duration?: number }>()
);

export const infoMessage = createAction(
  '[UI] InfoMessage',
  props<{ message: string; duration?: number }>()
);

export const clearErrorMessage = createAction('[UI] ClearErrorMessage');

export const noAction = createAction('[UI] No Action');

export const resetState = createAction('[UI] ResetState');

export const loadProfil = createAction(
  '[UI] LoadProfil',
  props<{
    redirectUrl?: string;
  }>()
);

export const loadProfilDone = createAction(
  '[UI] LoadProfilDone',
  props<{
    idLieu: string;
    idUtilisateur: number;
    civilite: string;
    id_profil: number;
    nom: string;
    mail: string;
    prenom: string;
    dateDeNaissance: string;
    courriel: string;
    telephoneBureau: string;
    telephonePortable: string;
    adresse: string;
    login: string;
    pCivMatcle: string;
    idPoste: number;
    libellePoste: string;
    fonction: string;
    grade: string;
    indiceMajore: number;
    dateFinDroits: string;
    libelleFopComp: string;
    libelleFop: string;
    libelleAffectation: string;
    affectationLong: string;
    rSerLibl: string;
    idAffectation: number;
    token: string;
    idOdmCadreGeneral: number;
    libelleOdmCadreGeneral: string;
    idFederation: number;
    saisonSportiveFederation: string;
    libelleRole: string;
    droitRefFederation: boolean;
    path_scan: string;
    id_scan: number;
    redirectUrl: string;
  }>()
);

export const loadVersion = createAction('[UI] loadVersion');

export const loadVersionDone = createAction('[UI] loadVersionDone', props<{ version: string }>());

export const setPageTitle = createAction(
  '[UI] Set Page Title',
  props<{ title: string; icon: string; dashboard?: boolean }>()
);

export const loadPastilles = createAction('[UI] loadPastilles');

export const loadPastillesDone = createAction(
  '[UI] loadPastillesDone',
  props<{
    pastilleDeplacementsGA: number;
    pastilleActualitesGA: number;
    pastilleLettresDeMissionsGA: number;
    pastilleCongesGA: number;
    pastillesServicesGA: number;
    pastillesServicesGCTS: number;
    pastilleDeplacementsGCTS: number;
    pastilleActualitesGCTS: number;
    pastilleLettresDeMissionsGCTS: number;
    pastilleCongesGCTS: number;
    pastilleFormationGCTS: number;
    pastilleFormationGA: number;
  }>()
);

export const logout = createAction('[UI] Logout');

export const addLoading = createAction('[UI] AddLoading', props<{ key: string }>());

export const removeLoading = createAction('[UI] RemoveLoading', props<{ key: string }>());

export const loadAllAide = createAction('[UI] loadAllAide');

export const loadAllAideDone = createAction(
  '[UI] LoadAllAideDone',
  props<{
    data: ItemAllAides[];
  }>()
);

export const getStaticText = createAction('[UI] getStaticText');

export const getStaticTextDone = createAction(
  '[UI] getStaticTextDone',
  props<{
    staticText: string;
    emailAssistance: EmailAssistance;
  }>()
);

export const getStaticTextFormation = createAction('[UI] getStaticTextFormation');

export const getStaticTextFormationDone = createAction(
  '[UI] getStaticTextFormationDone',
  props<{
    staticTextFormation: TextFormation;
  }>()
);

export const openAllAide = createAction('[UI] openAllAide');
export const getEmailAssistance = createAction('[UI] getEmailAssistance');
export const getEmailAssistanceDone = createAction(
  '[UI] getEmailAssistanceDone',
  props<{ emailAssist: EmailAssistance }>()
);
export const getCalendrierFormInfo = createAction('[GERER-CONGES] getCalendrierFormInfo');
export const getCalendrierFormInfoDone = createAction('[GERER-CONGES] getCalendrierFormInfoDone',
  props<{
    calendrier_scolaire: boolean;
  }>()
);
