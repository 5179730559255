import { MatPaginatorIntl } from '@angular/material/paginator';
import { Injectable } from '@angular/core';

@Injectable()
export class MatPaginatorIntlFr extends MatPaginatorIntl {
  itemsPerPageLabel = 'Éléments par page : ';
  nextPageLabel = 'Page suivante';
  previousPageLabel = 'Page précédente';

  getRangeLabel = (page: number, pageSize: number, length: number): string => {
    if (length <= 0) {
      return '';
    }
    return page * pageSize + 1 + ' - ' + (page * pageSize + pageSize) + ' sur ' + length;
  };
}
