<div class="contenerloaderpage" style="background: #00000099; display: block" *ngIf="loading$ | async">
  <div class="sk-cube-grid">
    <div class="sk-cube sk-cube1"></div>
    <div class="sk-cube sk-cube2"></div>
    <div class="sk-cube sk-cube3"></div>
    <div class="sk-cube sk-cube4"></div>
    <div class="sk-cube sk-cube5"></div>
    <div class="sk-cube sk-cube6"></div>
    <div class="sk-cube sk-cube7"></div>
    <div class="sk-cube sk-cube8"></div>
    <div class="sk-cube sk-cube9"></div>
  </div>
  <div class="msg">
    Chargement en cours...
    <br />
    Veuillez patienter.
    <br />
    <h1 id="calculatePourcentageLoadingApi"></h1>
  </div>
</div>

<header id="header_main">
  <div id="main_menu">
    <div class="container">
      <div class="row">
        <div class="col-md-3 col-xs-3">
          <div class="row">
            <div class="col-md-12">
              <a href="{{ pageAccueil$ | async }}" class="homelink">CTS WEB</a>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12" style="margin-top: -25px; padding-top: 0px; padding-left: 0px">
              <span style="display: inline-block; width: 65px; text-align: center; font-size: 9px; color: grey">
                Version {{ version$ | async }}
              </span>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="name">
            <p>Bonjour {{ (profillocal$ | async).prenom }} {{ (profillocal$ | async).nom }}</p>
          </div>
        </div>
        <div class="col-md-6">
          <ul class="menu big light pull-right right" style="margin-left: -14%">
            <ng-container *ngIf="showMenu$ | async">
              <li
                class="hiddenMobile visible-md-inline-block visible-lg-inline-block hidden-xs"
                *ngIf="droitsCTSSAnsInvit$ | async"
              >
                <a
                  [routerLink]="['/mon-activite/actualites']"
                  *ngIf="notCTS$ | async"
                  routerLinkActive="active"
                  class="rub0"
                >
                  <span>Gérer mon activité</span>
                </a>
              </li>
              <li
                class="hiddenMobile visible-md-inline-block visible-lg-inline-block hidden-xs"
                *ngIf="droitsProfil$ | async"
              >
                <a [routerLink]="['/profil']" *ngIf="DroitsProfilBtn$ | async" routerLinkActive="active" class="rub1">
                  <span>Mon profil</span>
                </a>
              </li>
              <li
                class="hiddenMobile visible-md-inline-block visible-lg-inline-block hidden-xs"
                *ngIf="droitAfficherLM$ | async"
              >
                <a [routerLink]="['gerer-cts/gerer-lettres-de-missions/projets-lettres-missions']" routerLinkActive="active" class="rub1">
                  <span>Retour accueil</span>
                </a>
              </li>
              <li
                class="hiddenMobile visible-md-inline-block visible-lg-inline-block hidden-xs"
                *ngIf="droitAfficherGererConges$ | async"
            >
              <a [routerLink]="['gerer-cts/gerer-conges/en-attente']" routerLinkActive="active" class="rub1">
                <span>Gérer les CTS</span>
              </a>
            </li>
              <li
                class="hiddenMobile visible-md-inline-block visible-lg-inline-block hidden-xs"
                *ngIf="droitGererCts$ | async"
              >
                <a [routerLink]="['/gerer-cts/actualites/liste-actualites']" routerLinkActive="active" class="rub1">
                  <span>Gérer les CTS</span>
                </a>
              </li>
              <!-- <li class="hiddenMobile visible-lg-inline-block hidden-xs" *ngIf="droitAdmin$ | async">
                <a href="/administration" class="rub2">
                  <span>Administration</span>
                </a>
              </li> -->
            </ng-container>
            <li
              class="inline-block hiddenMobile hidden-xs liDisconect"
              style="margin-right: -1.5%; background-color: aliceblue"
              *ngIf="!(menuMobile$ | async)"
              (click)="deconnexion()"
            >
              <a class="image iconDeconnexion">
                <svg
                  enable-background="new 0 0 32 32"
                  id="Layer_4"
                  version="1.1"
                  viewBox="0 0 32 32"
                  xml:space="preserve"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  width="32"
                  class="imgWrap"
                >
                  <g>
                    <line
                      fill="none"
                      stroke="#676767"
                      stroke-linejoin="round"
                      stroke-miterlimit="10"
                      stroke-width="2"
                      x1="28"
                      x2="1"
                      y1="16"
                      y2="16"
                    />
                    <polyline
                      fill="none"
                      points="7,22 1,16 7,10     "
                      stroke="#676767"
                      stroke-linejoin="round"
                      stroke-miterlimit="10"
                      stroke-width="2"
                    />
                    <path
                      d="M9,8V3   c0-1.105,0.895-2,2-2h18c1.105,0,2,0.895,2,2v26c0,1.105-0.895,2-2,2H11c-1.105,0-2-0.895-2-2v-5"
                      fill="none"
                      stroke="#676767"
                      stroke-linejoin="round"
                      stroke-miterlimit="10"
                      stroke-width="2"
                    />
                  </g>
                </svg>
              </a>
            </li>
            <li
              class="inline-block hiddenMobile hidden-xs"
              style="margin-right: -1.5%; background-color: aliceblue"
              *ngIf="!(menuMobile$ | async)"
              (click)="getAllAide()"
            >
              <a class="image" id="aideHeader" style="height: 106px">
                <img class="imgWrap" data-toggle="modal" src="/ui/images/icon-help.png" alt="" />
              </a>
            </li>
            <li
              class="inline-block hiddenMobile hidden-xs"
              style="margin-right: -1.5%; background-color: aliceblue; margin-top: -3px"
            >
              <a
                [href]="
                  (emailAssistance$ | async) !== null
                    ? 'mailto:' + (emailAssistance$ | async).email
                    : 'mailto:cts-web.assistance@sports.gouv.fr'
                "
                class="image"
                style="height: 106px"
              >
                <i class="icon-action-email"></i>
              </a>
            </li>
            <li class="inline-block hiddenDesktop hiddenTablet" *ngIf="menuMobile$ | async">
              <span class="search-link active icon-link">
                <i *ngIf="droitCts$ | async" class="icon-big-search" (click)="openAndClosSearchMobile()"></i>
              </span>
            </li>
            <li
              class="inline-block hiddenTablet hiddenDesktop"
              style="padding: 29px 10px 29px 15px"
              *ngIf="menuMobile$ | async"
            >
              <div class="icon-link icon-link-menu" (click)="showBurgerMenu()">
                <i class="icon-big-menu"></i>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="hiddenTablet hiddenTablet contener-searchFormMobile">
      <ul class="menu searchFormMobile" style="display: flex">
        <li style="width: 80%" class="contentField">
          <input type="text" id="searchInputMobile" placeholder="Recherche" />
        </li>
        <li style="margin-top: 2%">
          <button class="btn" (click)="submitSearchMobile()">OK</button>
        </li>
        <li style="margin-top: 2%">
          <img
            src="/ui/images/closesearch.png"
            onclick="$('.contener-searchFormMobile').hide();$('.nav-render-calendar').show();"
          />
        </li>
      </ul>
    </div>

    <div class="mobile_menu" style="display: none" *ngIf="menuMobile$ | async">
      <div class="profile_header">
        <div class="id">
          <i class="icon-user"></i>
          <ul class="user_info">
            <li>
              <span class="firstname">{{ (profillocal$ | async).prenom }}</span>
            </li>
            <li>
              <span class="lastname">{{ (profillocal$ | async).nom }}</span>
            </li>
          </ul>
        </div>
      </div>
      <div class="linkProfil">
        <i class="icon-mon-profil"></i>
        <a [routerLink]="['/profil']" (click)="hideBurgerMenu()">Mon profil</a>
      </div>
      <div class="linkLogOut">
        <i class="icon-deconnexion"></i>
        <a id="logoutStorage" (click)="deconnexion()" class="logout_link">Déconnexion</a>
      </div>
      <ul>
        <!-- <li ng-repeat="itemMenu in menuN1 track by $index" ng-if="itemMenu.actif && itemMenu.mobile"  name="color_scheme" id="color_scheme">
                    <a  selected="selected" ng-href="{{ itemMenu.href }}" onclick="hideBurgerMenu();" class="rub{{$index}}Menuburger"><span style="margin-right:26px;" class="active">{{ itemMenu.libelle }}</span></a><!-- a corriger
                </li> -->
        <!-- rework legende mobile -->
        <!-- <div>
                    <li id="burgerlegende" >
                        <a style="margin-left: 0%; margin-top: 0%; border-bottom: 1px solid rgb(211, 211, 211); padding: 11px 0px; text-decoration: none;" href="javascript:openToolBurgerMenu('burgerlegende');"
                           ng-show="profillocal.id_profil == 2 || profillocal.id_profil == 5 ||
                                                   profillocal.id_profil == 8 || profillocal.id_profil == 12"><img src="/ui/images/pinceau.png" alt="" style="font-size: 134%;margin-right: 6%;
                               color: grey;"/><span style="color: #676767;font-size: 20px;padding: 0px 0;">Légende&nbsp;&nbsp;</span><i class="icon-arrow-small" style="margin-bottom:3px;"></i></a>
                        <div class="share_info"  style="display:none;height:auto;">
                            <div class="legendeText" style="font-weight:bold;font-size:17px;text-decoration: underline;color: rgb(96, 157, 232);">Action</div>
                            <div class="legendeText" style="text-transform : capitalize;">{{dataLegende.action1[0].libelleLegende}}</div> <div  class="legendeColor" ng-style="{'background-color' : dataLegende.action1[0].backgroundColor}" style="color:#ffffff;width: 15px;height: 26px;"></div>
                            <div class="legendeText" style="text-transform : capitalize;">{{dataLegende.action2[0].libelleLegende}}</div> <div class="legendeColor" ng-style="{'background-color' : dataLegende.action2[0].backgroundColor}" style="color:#ffffff;width: 15px;height: 26px;"></div>
                            <div class="legendeText" style="text-transform : capitalize;">{{dataLegende.action3[0].libelleLegende}}</div> <div class="legendeColor" ng-style="{'background-color' : dataLegende.action3[0].backgroundColor}" style="color:#ffffff;width: 15px;height: 26px;"></div>
                            <div class="legendeText" style="text-transform : capitalize;">{{dataLegende.action4[0].libelleLegende}}</div> <div class="legendeColor" ng-style="{'background-color' : dataLegende.action4[0].backgroundColor}" style="color:#ffffff;width: 15px;height: 26px;"></div>

                            <div class="legendeText" style="font-weight:bold;font-size:17px;text-decoration: underline;color: rgb(96, 157, 232);">Ordre de mission</div>
                            <div class="legendeText">{{dataLegende.odmADemander[0].libelleLegende}}</div> <div class="legendeColor" ng-style="{'background-color' : dataLegende.odmADemander[0].backgroundColor}" style="color:#ffffff;width:15px;height:15px;border-radius: 35px;"></div>
                            <div class="legendeText">{{dataLegende.odmDefaut[0].libelleLegende}}</div> <div class="legendeColor" ng-style="{'background-color' : dataLegende.odmDefaut[0].backgroundColor}" style="color:#ffffff;width:15px;height:15px;border-radius: 35px;"></div>
                            <div class="legendeText">{{dataLegende.odmDefaut[0].libelleLegende}}</div> <div class="legendeColor" ng-style="{'background-color' : dataLegende.odmSigne[0].backgroundColor}" style="color:#ffffff;width:15px;height:15px;border-radius: 35px;"></div>
                            <div style="clear:both"></div>

                            <div class="legendeText" style="font-weight:bold;font-size:17px;text-decoration: underline;color: rgb(96, 157, 232);">Congé</div>
                            <div class="legendeText">{{dataLegende.congeAutreConge[0].libelleLegende}}</div> <div class="legendeColor" ng-style="{'background-color' : dataLegende.congeAutreConge[0].backgroundColor}" style="color:#ffffff;width: 15px;height: 26px;"></div>
                            <div class="legendeText">{{dataLegende.congeRepos[0].libelleLegende}}</div> <div class="legendeColor" ng-style="{'background-color' : dataLegende.congeRepos[0].backgroundColor}" style="color:#ffffff;width: 15px;height: 26px;"></div>
                            <div class="legendeText">{{dataLegende.congeRttAnnuel[0].libelleLegende}}</div> <div class="legendeColor" ng-style="{'background-color' : dataLegende.congeRttAnnuel[0].backgroundColor}" style="color:#ffffff;width: 15px;height: 26px;"></div>
                            <div style="clear:both"></div>
                            <div style="clear:both"></div>
                            <div style="clear:both"></div>
                        </div>
                    </li>
                </div> -->
      </ul>
      <!-- <a href="javascript:hideBurgerMenu();" class="close_btn"><i class="icon-close"></i></a> -->
    </div>
  </div>
</header>

<section>
  <header>
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="commentaire">
            {{ staticText$ | async }}
          </div>
        </div>
        <!-- <div class="box CTS-col-xs-6 col-sm-6 col-md-6 col-lg-6">
          <h1 class="page-title">
            <i class="{{ curentPageIcon$ | async }}"></i>
            {{ curentPageTitle$ | async }}
          </h1>
        </div> -->
      </div>
    </div>
  </header>

  <router-outlet></router-outlet>
</section>

<footer>
  <div class="row">
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <div class="row">
            <div class="col-md-12">
              <a href="{{ pageAccueil$ | async }}" class="bottomlink hidden-xs">< CTS WEB</a>
            </div>
          </div>
          <div class="row">
            <div
              class="col-md-12"
              style="
                margin-top: -40px;
                margin-left: 0px;
                padding-top: 6px;
                padding-left: 0px;
                color: #fff;
                font-size: 9px;
              "
            >
              <span style="display: inline-block; width: 60px; text-align: center">Version {{ version$ | async }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>
