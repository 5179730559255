import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class IsSignedInGuard implements CanActivate {
  constructor(
    private router: Router,
    private http: HttpClient) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.http.get("/api/isConnected").pipe(
      catchError(err => {
        window.location.href = '/login';
        return of({
          success: false
        });
      }),
      map((result: any) => {
        if (result.success && result.isConnected) {
          return true;
        }
        window.location.href = '/login';
        return false;
      }
      ))
  }
}
