import { createSelector } from '@ngrx/store';
import { ROLES } from 'src/app/constant';
import { AppState } from '..';

export const selectUi = (state: AppState) => state.ui;

export const selectRouter = (state: AppState) => state.router;

export const selectProfil = createSelector(selectUi, state => ({
  idUtilisateur: state.idUtilisateur,
  civilite: state.civilite,
  id_profil: state.id_profil,
  nom: state.nom,
  prenom: state.prenom,
  identifiant: state.identifiant,
  courriel: state.courriel,
  dateDeNaissance: state.dateDeNaissance,
  telephoneBureau: state.telephoneBureau,
  telephonePortable: state.telephonePortable,
  adresse: state.adresse,
  login: state.login,
  pCivMatcle: state.pCivMatcle,
  idPoste: state.idPoste,
  libellePoste: state.libellePoste,
  fonction: state.fonction,
  grade: state.grade,
  indiceMajore: state.indiceMajore,
  dateFinDroits: state.dateFinDroits,
  libelleFopComp: state.libelleFopComp,
  libelleFop: state.libelleFop,
  libelleAffectation: state.libelleAffectation,
  affectationLong: state.affectationLong,
  rSerLibl: state.rSerLibl,
  idAffectation: state.idAffectation,
  token: state.token,
  idOdmCadreGeneral: state.idOdmCadreGeneral,
  libelleOdmCadreGeneral: state.libelleOdmCadreGeneral,
  idFederation: state.idFederation,
  saisonSportiveFederation: state.saisonSportiveFederation,
  libelleRole: state.libelleRole,
  droitRefFederation: state.droitRefFederation,
  path_scan: state.path_scan,
  id_scan: state.id_scan
}));
export const selectUserId = createSelector(selectUi, state => state.idUtilisateur);
export const selectVersion = createSelector(selectUi, state => state.version);
export const selectFederation = createSelector(selectUi, state => state.rSerLibl);
export const selectAffectation = createSelector(selectUi, state => state.idAffectation);
export const selectRoleLecteur = createSelector(
  selectUi,
  state => state.id_profil == ROLES.ROLE_LECTEUR
);
export const selectRoleGestionnaireCongesDS = createSelector(
  selectUi,
  state => state.id_profil == ROLES.ROLE_GCS
);
export const selectRoleGestionnaireCongesDR = createSelector(
  selectUi,
  state => state.id_profil == ROLES.ROLE_GCR
);

// TODO : Mettre les bons droits
export const selectDroitAdmin = createSelector(
  selectUi,
  state => state.id_profil == ROLES.ROLE_DS_ADMIN
);
export const selectDroitFiltreFedeDeplacement = createSelector(
  selectUi,
  state =>
    state.id_profil == ROLES.ROLE_DS_ADMIN ||
    state.id_profil == ROLES.ROLE_DS_SIGNATAIRE ||
    state.id_profil == ROLES.ROLE_DS_INVITE ||
    state.id_profil == ROLES.ROLE_DR ||
    state.id_profil == ROLES.ROLE_DR_SIGNATAIRE
);
export const selectDroitLecteur = createSelector(
  selectUi,
  state => state.id_profil == ROLES.ROLE_LECTEUR
);
export const selectDroitDsInvite = createSelector(
  selectUi,
  state => state.id_profil == ROLES.ROLE_DS_INVITE
);
export const selectDroitDsSignataire = createSelector(
  selectUi,
  state => state.id_profil == ROLES.ROLE_DS_INVITE
);
export const selectDroitDS = createSelector(
  selectUi,
  state => state.id_profil == ROLES.ROLE_DS_INVITE || state.id_profil == ROLES.ROLE_DS_SIGNATAIRE
);
export const selectDroitsDtn = createSelector(
  selectUi,
  state => state.id_profil == ROLES.ROLE_FEDE || state.id_profil == ROLES.ROLE_FEDE_INVITE
);
export const selectDroitDr = createSelector(selectUi, state => state.id_profil == ROLES.ROLE_DR);
export const selectDroitDrSignataire = createSelector(
  selectUi,
  state => state.id_profil == ROLES.ROLE_DR_SIGNATAIRE
);
export const selectDroitAllDr = createSelector(
  selectUi,
  state => state.id_profil == ROLES.ROLE_DR || state.id_profil == ROLES.ROLE_DR_SIGNATAIRE
);
export const selectDroitFede = createSelector(
  selectUi,
  state => state.id_profil == ROLES.ROLE_FEDE
);
export const selectDroitFedeInvite = createSelector(
  selectUi,
  state => state.id_profil == ROLES.ROLE_FEDE_INVITE
);

// (isLecteur$ | async)
export const selectDroitProfilBtn = createSelector(
  selectUi,
  state =>
    state.id_profil == ROLES.ROLE_DS_ADMIN ||
    state.id_profil == ROLES.ROLE_DR ||
    state.id_profil == ROLES.ROLE_FEDE_INVITE ||
    state.id_profil == ROLES.ROLE_DR_SIGNATAIRE ||
    state.id_profil == ROLES.ROLE_DS_SIGNATAIRE ||
    state.id_profil == ROLES.ROLE_DS_INVITE ||
    state.id_profil == ROLES.ROLE_LECTEUR ||
    state.id_profil == ROLES.ROLE_GCR ||
    state.id_profil == ROLES.ROLE_GCS
);
// TODO : Mettre les bons droits
export const selectDroitCts = createSelector(
  selectUi,
  state =>
    state.id_profil == ROLES.ROLE_CTS ||
    state.id_profil == ROLES.ROLE_FEDE ||
    state.id_profil == ROLES.ROLE_FEDE_INVITE
);
export const selectDroitCtsSansInvit = createSelector(
  selectUi,
  state => state.id_profil == ROLES.ROLE_CTS || state.id_profil == ROLES.ROLE_FEDE
);
export const selectDroitNotCTS = createSelector(
  selectUi,
  state => state.id_profil != ROLES.ROLE_CTS || state.id_profil != ROLES.ROLE_FEDE_INVITE
);
export const selectDroitNotCTSnotFedeInvit = createSelector(
  selectUi,
  state => state.id_profil != ROLES.ROLE_CTS
);
// TODO : Mettre les bons droits
export const selectDroitGererCts = createSelector(
  selectUi,
  state =>
    state.id_profil == ROLES.ROLE_DS_ADMIN ||
    state.id_profil == ROLES.ROLE_DS_INVITE ||
    state.id_profil == ROLES.ROLE_DS_SIGNATAIRE ||
    state.id_profil == ROLES.ROLE_DR ||
    state.id_profil == ROLES.ROLE_DR_SIGNATAIRE ||
    state.id_profil == ROLES.ROLE_FEDE ||
    state.id_profil == ROLES.ROLE_FEDE_INVITE
);
export const selectDroitAfficherLM = createSelector(
  selectUi,
  state => state.id_profil == ROLES.ROLE_LECTEUR
);

export const selectDroitAfficherGererConges = createSelector(
  selectUi,
  state => 
    state.id_profil == ROLES.ROLE_GCR ||
    state.id_profil == ROLES.ROLE_GCS
);

export const selectDroitGererProfil = createSelector(
  selectUi,
  state =>
    state.id_profil == ROLES.ROLE_DS_ADMIN ||
    state.id_profil == ROLES.ROLE_DS_INVITE ||
    state.id_profil == ROLES.ROLE_DS_SIGNATAIRE ||
    state.id_profil == ROLES.ROLE_DR ||
    state.id_profil == ROLES.ROLE_DR_SIGNATAIRE ||
    state.id_profil == ROLES.ROLE_FEDE ||
    state.id_profil == ROLES.ROLE_FEDE_INVITE ||
    state.id_profil == ROLES.ROLE_LECTEUR ||
    state.id_profil == ROLES.ROLE_GCR ||
    state.id_profil == ROLES.ROLE_GCS 
);
export const selectDroitAffichActivité = createSelector(
  selectUi,
  state =>
    state.id_profil == ROLES.ROLE_DS_ADMIN ||
    state.id_profil == ROLES.ROLE_DS_INVITE ||
    state.id_profil == ROLES.ROLE_DS_SIGNATAIRE ||
    state.id_profil == ROLES.ROLE_DR ||
    state.id_profil == ROLES.ROLE_DR_SIGNATAIRE ||
    state.id_profil == ROLES.ROLE_FEDE_INVITE
);
export const selectCurrentPageTitle = createSelector(selectUi, state => state.pageTitle);

export const selectListeAide = createSelector(selectUi, state =>
  state.allAides.filter(c => c.type !== 'agenda')
);

export const selectCurrentPageIcon = createSelector(selectUi, state => state.pageIcon);

export const selectShowDashboard = createSelector(selectUi, state => state.dashboard);

export const selectPastillesGA = createSelector(selectUi, state => ({
  pastilleDeplacements: state.pastilleDeplacementsGA,
  pastilleActualites: state.pastilleActualitesGA,
  pastilleLettresDeMissions: state.pastilleLettresDeMissionsGA,
  pastilleConges: state.pastilleCongesGA,
  pastilleFormations: state.pastilleFormationGA,
  pastillesServices: state.pastillesServicesGA,
}));

export const selectPastillesGCTS = createSelector(selectUi, state => ({
  pastilleDeplacements: state.pastilleDeplacementsGCTS,
  pastilleActualites: state.pastilleActualitesGCTS,
  pastilleLettresDeMissions: state.pastilleLettresDeMissionsGCTS,
  pastilleConges: state.pastilleCongesGCTS,
  pastilleFormations: state.pastilleFormationGCTS,
  pastillesServices: state.pastillesServicesGCTS,
}));

export const selectIsLoading = createSelector(selectUi, state => state.loadings.length > 0);

export const selectStaticText = createSelector(selectUi, state => state.staticText);
export const selectTextFormation = createSelector(selectUi, state => state.textFormation);

export const selectShowMenu = createSelector(selectUi, state => state.showMenu);
export const selectEmailAssistance = createSelector(selectUi, state => state.emailAssistance);
export const selectPageAccueil = createSelector(selectUi, state => {
  if (state.id_profil == ROLES.ROLE_CTS) {
    return '/v2/mon-activite/mes-deplacements';
  } else if (state.id_profil == ROLES.ROLE_LECTEUR) {
    return '/v2/gerer-cts/gerer-lettres-de-missions/projets-lettres-missions';
  } else if (state.id_profil == ROLES.ROLE_GCR || state.id_profil == ROLES.ROLE_GCS) {
    return '/v2/gerer-cts/gerer-conges/en-attente';
  }else {
    return '/v2/gerer-cts/gerer-conges';
  }
});
export const selectIsAnneeCivile = createSelector(selectUi, state => state.anneeCivile);
