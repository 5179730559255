import { NgModule } from '@angular/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDialogModule, MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';

import { FormsModule } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { Platform, PlatformModule } from '@angular/cdk/platform';
import { MaterialModule } from './material.module';
import { AppDateAdapter, MY_FORMAT } from './date-adapter';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { MessageDialogComponent } from './message-dialog/message-dialog.component';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { NgrxFormsModule } from 'ngrx-forms';
import { environment } from 'src/environments/environment';

import { SanitizeHtmlPipe } from './pipe/sanitize-html.pipe';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { FiltreComponent } from './filtre/filtre.component';
import { CommonModule } from '@angular/common';
import { SpinnerComponent } from './spinner/spinner.component';

@NgModule({
  imports: [
    MaterialModule,
    PlatformModule,
    MatAutocompleteModule,
    MatDialogModule,
    FormsModule,
    NgrxFormsModule,
    BsDatepickerModule,
    CommonModule
  ],
  exports: [
    MaterialModule,
    PlatformModule,
    MatAutocompleteModule,
    FormsModule,
    NgrxFormsModule,
    ConfirmationDialogComponent,
    MessageDialogComponent,
    SanitizeHtmlPipe,
    BsDatepickerModule,
    FiltreComponent,
    SpinnerComponent
  ],
  declarations: [
    ConfirmationDialogComponent,
    MessageDialogComponent,
    SanitizeHtmlPipe,
    FiltreComponent,
    SpinnerComponent
  ],
  providers: [
    {
      provide: DateAdapter,
      useClass: AppDateAdapter,
      deps: [MAT_DATE_LOCALE, Platform]
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMAT },
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'fr-FR'
    }
  ]
})
export class SharedModule { }
