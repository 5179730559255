import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { AppState } from './reducers';
import * as fromUi from './reducers/ui';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit {
  DroitsProfilBtn$ = this.store.pipe(select(fromUi.selectDroitProfilBtn));


  profillocal$ = this.store.pipe(select(fromUi.selectProfil));
  droitCts$ = this.store.pipe(select(fromUi.selectDroitCts));
  droitGererCts$ = this.store.pipe(select(fromUi.selectDroitGererCts));
  droitAfficherLM$ = this.store.pipe(select(fromUi.selectDroitAfficherLM));
  droitAfficherGererConges$ = this.store.pipe(select(fromUi.selectDroitAfficherGererConges));
  droitAdmin$ = this.store.pipe(select(fromUi.selectDroitAdmin));
  version$ = this.store.pipe(select(fromUi.selectVersion));
  menuMobile$ = of(false);
  pageAccueil$ = this.store.pipe(select(fromUi.selectPageAccueil));
  curentPageIcon$ = this.store.pipe(select(fromUi.selectCurrentPageIcon));
  curentPageTitle$ = this.store.pipe(select(fromUi.selectCurrentPageTitle));
  loading$ = this.store.pipe(select(fromUi.selectIsLoading));
  staticText$ = this.store.pipe(select(fromUi.selectStaticText));
  showMenu$ = this.store.pipe(select(fromUi.selectShowMenu));
  notCTS$ = this.store.pipe(select(fromUi.selectDroitNotCTS));
  droitsProfil$ = this.store.pipe(select(fromUi.selectDroitGererProfil));
  emailAssistance$ = this.store.pipe(select(fromUi.selectEmailAssistance));
  droitsCTSSAnsInvit$ = this.store.pipe(select(fromUi.selectDroitCtsSansInvit));

  constructor(private store: Store<AppState>) { }

  ngOnInit() {
    this.store.dispatch(fromUi.loadProfil({}));
    this.store.dispatch(fromUi.loadVersion());
    this.store.dispatch(fromUi.loadPastilles());
    this.store.dispatch(fromUi.loadAllAide());
    this.store.dispatch(fromUi.getStaticText());
    this.store.dispatch(fromUi.getCalendrierFormInfo());

  }

  deconnexion() {
    this.store.dispatch(fromUi.logout());
  }

  getAllAide() {
    this.store.dispatch(fromUi.openAllAide());
  }

  submitSearchMobile() { }

  hideBurgerMenu() { }

  showBurgerMenu() { }

  openAndClosSearchMobile() { }
}
