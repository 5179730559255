import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from '../reducers';
import * as fromSelect from '../reducers/ui/ui.selectors';
import * as fromAction from '../reducers/ui/ui.actions';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-aide-notices',
  templateUrl: './modal-aide-notices.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalAideNoticesComponent implements OnInit {
  listeAide$ = this.store.pipe(select(fromSelect.selectListeAide));

  constructor(
    private store: Store<AppState>,
    public dialogRef: MatDialogRef<ModalAideNoticesComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {}
  ) {}

  ngOnInit() {
    this.store.dispatch(fromAction.loadAllAide());
  }

  close() {
    this.dialogRef.close();
  }
}
