import { createReducer, on, Action } from '@ngrx/store';
import { loadAllAideDone, loadProfilDone, getEmailAssistanceDone, resetState } from './ui.actions';
import * as fromActions from './ui.actions';
import { act } from '@ngrx/effects';

export interface ItemAllAides {
  id: number;
  path: string;
  nom: string;
  type: string;
  description: string;
}
export interface EmailAssistance {
  email: string;
}

export interface TextFormation {
  textAttention1: string;
  textAttention2: string;
  textCommentaire1: string;
  textCommentaire2: string;
  textCommentaire3: string;
}

export interface State {
  emailAssistance: EmailAssistance;
  idLieu: string;
  idUtilisateur: number;
  civilite: string;
  nom: string;
  prenom: string;
  id_profil: number;
  courriel: string;
  dateNaissance: string;
  finDroit: string;
  identifiant: string;
  matricule: string;
  dateDeNaissance: string;
  telephoneBureau: string;
  telephonePortable: string;
  adresse: string;
  login: string;
  pCivMatcle: string;
  idPoste: number;
  libellePoste: string;
  fonction: string;
  grade: string;
  indiceMajore: number;
  dateFinDroits: string;
  libelleFopComp: string;
  libelleFop: string;
  libelleAffectation: string;
  affectationLong: string;
  rSerLibl: string;
  idAffectation: number;
  token: string;
  idOdmCadreGeneral: number;
  libelleOdmCadreGeneral: string;
  idFederation: number;
  saisonSportiveFederation: string;
  libelleRole: string;
  droitRefFederation: boolean;
  path_scan: string;
  id_scan: number;
  version: string;
  pageTitle: string;
  pageIcon: string;
  dashboard: boolean;
  pastilleDeplacementsGA: number;
  pastilleActualitesGA: number;
  pastilleLettresDeMissionsGA: number;
  pastilleCongesGA: number;
  pastilleFormationGA: number;
  pastilleDeplacementsGCTS: number;
  pastilleActualitesGCTS: number;
  pastilleLettresDeMissionsGCTS: number;
  pastilleCongesGCTS: number;
  pastilleFormationGCTS: number;
  pastillesServicesGA: number;
  pastillesServicesGCTS: number;
  loadings: string[];
  allAides: ItemAllAides[];
  staticText: string;
  staticTextGeneral: TextFormation;
  textFormation: TextFormation;
  showMenu: boolean;
  anneeCivile: boolean;
}

const InitialState: State = {
  anneeCivile: null,
  textFormation: null,
  emailAssistance: null,
  idLieu: '',
  civilite: '',
  id_profil: -1,
  nom: '',
  prenom: '',
  courriel: '',
  idUtilisateur: -1,
  dateNaissance: '',
  finDroit: '',
  identifiant: '',
  matricule: '',
  dateDeNaissance: '',
  telephoneBureau: '',
  telephonePortable: '',
  adresse: '',
  login: '',
  pCivMatcle: '',
  idPoste: null,
  libellePoste: '',
  fonction: '',
  grade: '',
  indiceMajore: null,
  dateFinDroits: '',
  libelleFopComp: '',
  libelleFop: '',
  libelleAffectation: '',
  affectationLong: '',
  rSerLibl: '',
  idAffectation: null,
  token: '',
  idOdmCadreGeneral: null,
  libelleOdmCadreGeneral: '',
  idFederation: null,
  saisonSportiveFederation: '',
  libelleRole: '',
  droitRefFederation: false,
  path_scan: null,
  id_scan: null,
  version: '',
  pageTitle: null,
  pageIcon: null,
  dashboard: false,
  pastilleDeplacementsGA: null,
  pastilleActualitesGA: null,
  pastilleLettresDeMissionsGA: null,
  pastilleCongesGA: null,
  pastilleDeplacementsGCTS: null,
  pastilleActualitesGCTS: null,
  pastilleLettresDeMissionsGCTS: null,
  pastilleCongesGCTS: null,
  pastilleFormationGCTS: null,
  pastilleFormationGA: null,
  pastillesServicesGA: null,
  pastillesServicesGCTS: null,
  loadings: [],
  allAides: [],
  staticText: null,
  staticTextGeneral: null,
  showMenu: true
};

const innerReducer = createReducer(
  InitialState,
  on(getEmailAssistanceDone, (state, action) => {
    return {
      ...state,
      emailAssistance: action.emailAssist
    };
  }),
  on(loadAllAideDone, (state, action) => {
    return {
      ...state,
      allAides: action.data
    };
  }),

  on(fromActions.getStaticTextDone, (state, action) => {
    return {
      ...state,
      staticText: action.staticText,
      emailAssistance: action.emailAssistance
    };
  }),
  on(fromActions.getStaticTextFormationDone, (state, action) => {
    return {
      ...state,
      textFormation: action.staticTextFormation
    };
  }),

  on(loadProfilDone, (state, action) => {
    function isNullOrWhitespace(input) {
      return !input || !input.trim();
    }

    return {
      ...state,
      idLieu: action.idLieu,
      idUtilisateur: action.idUtilisateur,
      civilite: action.civilite,
      id_profil: action.id_profil,
      nom: action.nom,
      prenom: action.prenom,
      dateDeNaissance: action.dateDeNaissance,
      courriel: action.courriel,
      telephoneBureau: action.telephoneBureau,
      telephonePortable: action.telephonePortable,
      adresse: action.adresse,
      login: action.login,
      pCivMatcle: action.pCivMatcle,
      idPoste: action.idPoste,
      libellePoste: action.libellePoste,
      fonction: action.fonction,
      grade: action.grade,
      indiceMajore: action.indiceMajore,
      dateFinDroits: action.dateFinDroits,
      libelleFopComp: action.libelleFopComp,
      libelleFop: action.libelleFop,
      libelleAffectation: action.libelleAffectation,
      affectationLong: action.affectationLong,
      rSerLibl: action.rSerLibl,
      idAffectation: action.idAffectation,
      token: action.token,
      idOdmCadreGeneral: action.idOdmCadreGeneral,
      libelleOdmCadreGeneral: action.libelleOdmCadreGeneral,
      idFederation: action.idFederation,
      saisonSportiveFederation: action.saisonSportiveFederation,
      libelleRole: action.libelleRole,
      droitRefFederation: action.droitRefFederation,
      path_scan: action.path_scan,
      id_scan: action.id_scan,
      showMenu:
        !isNullOrWhitespace(action.courriel) &&
        !isNullOrWhitespace(action.adresse) &&
        (!isNullOrWhitespace(action.telephoneBureau) ||
          !isNullOrWhitespace(action.telephonePortable))
    };
  }),

  on(fromActions.loadVersionDone, (state, action) => {
    return {
      ...state,
      version: action.version
    };
  }),

  on(fromActions.setPageTitle, (state, action) => {
    return {
      ...state,
      pageTitle: action.title,
      pageIcon: action.icon,
      dashboard: action.dashboard == null ? true : action.dashboard
    };
  }),

  on(fromActions.loadPastillesDone, (state, action) => {
    return {
      ...state,
      pastilleDeplacementsGA: action.pastilleDeplacementsGA,
      pastilleActualitesGA: action.pastilleActualitesGA,
      pastilleLettresDeMissionsGA: action.pastilleLettresDeMissionsGA,
      pastilleCongesGA: action.pastilleCongesGA,
      pastilleDeplacementsGCTS: action.pastilleDeplacementsGCTS,
      pastilleActualitesGCTS: action.pastilleActualitesGCTS,
      pastilleLettresDeMissionsGCTS: action.pastilleLettresDeMissionsGCTS,
      pastilleCongesGCTS: action.pastilleCongesGCTS,
      pastilleFormationGA: action.pastilleFormationGA,
      pastilleFormationGCTS: action.pastilleFormationGCTS,
      pastillesServicesGA: action.pastillesServicesGA,
      pastillesServicesGCTS: action.pastillesServicesGCTS,

    };
  }),

  on(fromActions.addLoading, (state, action) => {
    return {
      ...state,
      loadings: [...state.loadings.filter(c => c !== action.key), action.key]
    };
  }),
  on(fromActions.getCalendrierFormInfoDone, (state, action) => {


    return {
      ...state,
      anneeCivile: action.calendrier_scolaire
    };
  }),
  on(fromActions.removeLoading, (state, action) => {
    return {
      ...state,
      loadings: state.loadings.filter(c => c !== action.key)
    };
  })
);

export function reducer(state: State | undefined, action: Action) {
  return innerReducer(state, action);
}
