import { createReducer, on, Action } from '@ngrx/store';
import {} from './filtre.actions';
import * as fromActions from './filtre.actions';
import { createFormGroupState, FormGroupState, onNgrxForms } from 'ngrx-forms';
import { state } from '@angular/animations';

export class RefAffectationController {
  codeRegion: string;
  idAffectation: number;
  libelleAffectation: string;
  libelleLong: string;
}

export class RefEtat {
  etape: number;
  id: number;
  libelle: string;
}

export class RefFederation {
  federationActive: boolean;
  idFederation: number;
  rAdmDiside: string;
  rSerLibc: string;
  rSerLibl: string;
}

export class RefFop {
  idPoste: number;
  libellePoste: string;
}

export class RefRegion {
  id: number;
  libelle: string;
}

export class RefProfil {
  id: number;
  libelle: string;
}

export class RefStatut {
  etape: number;
  id: number;
  libelle: string;
}
export class RefStatusFormation {
  etape: number;
  id: number;
  libelle: string;
}

export class RefContinent {
  id: number;
  libelle: string;
}

/*export class RefStatusRaa {
  statutsRaa: string;
}*/
export interface FiltreFormValue {
  gender: string;
  firstName: string;
  lastName: string;
  affectation: number;
  affectation_admin: number;
  federation: number;
  poste: number;
  etat: number;
  region: number;
  dateDebut: string;
  dateDep: string;
  dateFin: string;
  dateCritique: string;
  statut: string;
  avisDTN: boolean;
  continent: number;
  libellePays: string;
  ue: boolean;
  profil: number;
  etatActif: boolean;
  annee: string;
  status_raa: string; // Status RAA uniquement
  filtreAC: boolean;
}

export interface State {
  form: FormGroupState<FiltreFormValue>;
  affectations: RefAffectationController[];
  etats: RefEtat[];
  federations: RefFederation[];
  affectation_admin: RefAffectationController[];
  fops: RefFop[];
  regions: RefRegion[];
  statuts: RefStatut[];
  opened: boolean;
  continent: RefContinent[];
  profils: RefProfil[];
  filtreAC: boolean;
  status_formation: RefStatusFormation[]
  status_raa: string
}

const filtreFormInitValue: FiltreFormValue = {
  gender: null,
  firstName: null,
  lastName: null,
  affectation: null,
  affectation_admin: null,
  federation: null,
  poste: null,
  etat: null,
  region: null,
  dateDebut: null,
  dateDep: null,
  dateFin: null,
  dateCritique: null,
  statut: null,
  avisDTN: null,
  continent: null,
  libellePays: null,
  ue: null,
  profil: null,
  etatActif: null,
  annee: null,
  status_raa: null,
  filtreAC: null
};

const InitialState: State = {
  form: createFormGroupState<FiltreFormValue>('filtreFormId', filtreFormInitValue),
  affectations: [],
  affectation_admin: [],
  etats: [],
  federations: [],
  fops: [],
  regions: [],
  statuts: [],
  continent: [],
  profils: [],
  opened: false,
  filtreAC: false,
  status_formation: [],
  status_raa: ''
  
  
};

const innerReducer = createReducer(
  InitialState,

  onNgrxForms(),

  on(fromActions.loadRefDone, (state, action) => {
    return {
      ...state,
      affectations: [...action.affectation].sort((a, b) =>
        a.libelleAffectation.trim() > b.libelleAffectation.trim() ? 1 : -1
      ),
      affectation_admin: [...action.affectation_admin].sort((a, b) =>
        a.libelleAffectation.trim() > b.libelleAffectation.trim() ? 1 : -1
      ),
      etats: [...action.etat].sort((a, b) => (a.libelle.trim() > b.libelle.trim() ? 1 : -1)),
      federations: [...action.federation].sort((a, b) => (a.rSerLibl.trim() > b.rSerLibl ? 1 : -1)),
      fops: [...action.fop].sort((a, b) =>
        a.libellePoste.trim() > b.libellePoste.trim() ? 1 : -1
      ),
      regions: [...action.region].sort((a, b) => (a.libelle.trim() > b.libelle.trim() ? 1 : -1)),
      statuts: [...action.statut].sort((a, b) => (a.libelle.trim() > b.libelle.trim() ? 1 : -1)),
      //statuts_raa: [...action.statut].sort((a, b) => (a.libelle.trim() > b.libelle.trim() ? 1 : -1)),
      status_formation: [...action.status_formation].sort((a, b) => (a.libelle.trim() > b.libelle.trim() ? 1 : -1)),
    };
  }),
  on(fromActions.loadContinentDone, (state, action) => {
    return {
      ...state,
      continent: [...action.continent].sort((a, b) =>
        a.libelle.trim() > b.libelle.trim() ? 1 : -1
      )
    };
  }),
  on(fromActions.loadRefProfilDone, (state, action) => {
    return {
      ...state,
      profils: [...action.profil].sort((a, b) => (a.libelle.trim() > b.libelle.trim() ? 1 : -1))
    };
  }),
  on(fromActions.openClose, state => {
    return {
      ...state,
      opened: !state.opened
    };
  }),
  on(fromActions.loadFiltreACDone, (state, action) => {
    return {
      ...state,

      filtreAC: action.filtreAC
    };
  }),
  on(fromActions.resetFiltre, state => {
    return {
      ...state,
      form: createFormGroupState<FiltreFormValue>('filtreFormId', filtreFormInitValue)
    };
  })
);

export function reducer(state: State | undefined, action: Action) {
  return innerReducer(state, action);
}
