<div class="modal-content">
  <div class="modal-header">
    <div class="modal-body">
      <button type="button" class="close" (click)="close()">
        <span>×</span>
      </button>
      <h4 class="modal-title" id="myModalLabel" style="margin-right: 89%">Aide</h4>
      <div class="row">
        <section class="col-lg-12 noPaddingGeneral">
          <table class="table liste-libelle noPaddingGeneral">
            <tbody>
              <tr>
                <td class="entetetrie" style="width: 100%">Fiche de pratique/Guide général</td>
                <td class="entetetrie" style="float: right">Télécharger</td>
              </tr>
            </tbody>
          </table>
          <table class="table liste-odm-ldm noPaddingGeneral" id="table-line">
            <tbody>
              <tr *ngFor="let aide of listeAide$ | async">
                <td width="650" style="color: black; text-align: start">
                  <img src="/ui/images/icon-action-form.png" alt="voir le fichier" />
                  &nbsp;{{ aide.description }}
                </td>
                <td class="col-lg-6 col-md-6 col-sm-6 noPaddingGeneral">
                  <p class="viewOptionFileUploadodm" ng-if="aide.afficheAide">
                    <a
                      id="linkviewAide"
                      style="margin-left: 81%"
                      href="/{{ aide.path }}"
                      download="{{ aide.nom }}"
                      title="Télécharger le fichier"
                      target="_blank"
                    >
                      <img src="/ui/images/icon-action-view.png" alt="voir le fichier" />
                    </a>
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </section>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button value="enregistrer" class="button button-modal" (click)="close()">Fermer</button>
  </div>
</div>
