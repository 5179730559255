<nav class="dashboard-icons">
  <div class="container">
    <div class="big-icons row">
      <div class="col-12 navMenu">
        <div
          *ngIf="!(isLecteur$ | async) && !(isGestionnaireCongesDS$ | async) && !(isGestionnaireCongesDR$ | async)"
          class="navBtn"
          [routerLink]="['/gerer-cts/gerer-carrieres/gestion-des-cts']"
          routerLinkActive="active"
        >
          <span class="text">Gérer les carrières</span>
        </div>
        <div
          *ngIf="!(isLecteur$ | async) && !(isGestionnaireCongesDS$ | async) && !(isGestionnaireCongesDR$ | async)"
          class="navBtn"
          [routerLink]="['/gerer-cts/gerer-deplacements/en-attente']"
          routerLinkActive="active"
        >
          <span class="text">Gérer les déplacements</span>
          <span *ngIf="(pastilles$ | async).pastilleDeplacements as pastille" class="badgeNav">{{ pastille }}</span>
        </div>
        <div
          *ngIf="!(isLecteur$ | async)"
          class="navBtn"
          [routerLink]="['/gerer-cts/gerer-conges/en-attente']"
          routerLinkActive="active"
        >
          <span class="text">Gérer les congés</span>
          <span *ngIf="(pastilles$ | async).pastilleConges as pastille" class="badgeNav">{{ pastille }}</span>
        </div>
        <div
          *ngIf="!(isLecteur$ | async) && !(isGestionnaireCongesDS$ | async) && !(isGestionnaireCongesDR$ | async)"
          class="navBtn"
          [routerLink]="['/gerer-cts/actualites/liste-actualites']"
          routerLinkActive="active"
        >
          <span class="text">Gérer les actualités</span>
          <span *ngIf="(pastilles$ | async).pastilleActualites as pastille" class="badgeNav">{{ pastille }}</span>
        </div>
        <div
          *ngIf="!(isGestionnaireCongesDS$ | async) && !(isGestionnaireCongesDR$ | async)"
          class="navBtn"
          [routerLink]="['/gerer-cts/gerer-lettres-de-missions/projets-lettres-missions']"
          routerLinkActive="active"
        >
          <span class="text">Gérer les lettres de missions</span>
          <span *ngIf="(pastilles$ | async).pastilleLettresDeMissions as pastille" class="badgeNav">
            {{ pastille }}
          </span>
        </div>
        <div
          *ngIf="!(isLecteur$ | async) && !(isGestionnaireCongesDS$ | async) && !(isGestionnaireCongesDR$ | async)"
          class="navBtn"
          [routerLink]="['/gerer-cts/gerer-formations/en-attente']"
          routerLinkActive="active"
        >
          <span class="text">Gérer les formations</span>
          <span *ngIf="(pastilles$ | async)?.pastilleFormations as pastille" class="badgeNav">
            {{ pastille }}
          </span>
        </div>

        <div
          *ngIf="!(isLecteur$ | async) && !(isGestionnaireCongesDS$ | async) && !(isGestionnaireCongesDR$ | async)"
          class="navBtn"
          [routerLink]="['/gerer-cts/gestion-des-services/service']"
          routerLinkActive="active"
        >
          <span class="text">Gérer les services</span>
          <span *ngIf="(pastilles$ | async)?.pastillesServices as pastille" class="badgeNav">
            {{ pastille }}
          </span>
        </div>
      </div>
    </div>
  </div>
</nav>

<div class="container">
  <router-outlet></router-outlet>
</div>
<!-- <ng-container *ngIf="ac">
  <router-outlet></router-outlet>
</ng-container> -->
