import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { StoreModule, Store } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from 'src/environments/environment';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { CustomSerializer } from './shared/custom-route-serializer';
import { reducers, metaReducers } from './reducers';
import { UiEffects } from './reducers/ui/ui.effects';
import { SharedModule } from './shared/shared.module';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { GererCtsComponent } from './gerer-cts/gerer-cts.component';
import { MonActiviteComponent } from './mon-activite/mon-activite.component';
import { BsDatepickerModule, BsLocaleService, BsDatepickerConfig } from 'ngx-bootstrap/datepicker';

registerLocaleData(localeFr);

import { defineLocale } from 'ngx-bootstrap/chronos';
import { frLocale } from 'ngx-bootstrap/locale';
import { FiltreEffects } from './reducers/filtre/filtre.effects';
import { CheckSessionService } from './check-session.service';
import { ModalAideNoticesComponent } from './modal-aide-notices/modal-aide-notices.component';
import { QuillModule } from 'ngx-quill';

export function checkSessionServiceFactory(checkSessionService: CheckSessionService) {
  return () => checkSessionService.test();
}

@NgModule({
  declarations: [AppComponent, GererCtsComponent, MonActiviteComponent, ModalAideNoticesComponent],
  imports: [
    SharedModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    StoreModule.forRoot(reducers, {
      metaReducers
    }),
    StoreDevtoolsModule.instrument({
      maxAge: 50,
      logOnly: environment.production
    }),
    EffectsModule.forRoot([UiEffects, FiltreEffects]),
    StoreRouterConnectingModule.forRoot({
      stateKey: 'router',
      serializer: CustomSerializer
    }),
    BsDatepickerModule.forRoot(),
    QuillModule.forRoot()
  ],
  providers: [
    CheckSessionService,
    {
      provide: APP_INITIALIZER,
      useFactory: checkSessionServiceFactory,
      deps: [CheckSessionService],
      multi: true
    },
    {
      provide: LOCALE_ID,
      useValue: 'fr-FR'
    },
    BsDatepickerConfig,
  ],

  bootstrap: [AppComponent],

})
export class AppModule {
  constructor(private bsLocaleService: BsLocaleService) {
    defineLocale('fr', frLocale);
    this.bsLocaleService.use('fr');
  }
}
