import { Component, ChangeDetectionStrategy } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as fromUi from '../reducers/ui';
import { AppState } from '../reducers';
import { of } from 'rxjs';

@Component({
  selector: 'app-gerer-cts',
  templateUrl: './gerer-cts.component.html',
  styleUrls: ['./gerer-cts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GererCtsComponent {
  pastilles$ = this.store.pipe(select(fromUi.selectPastillesGCTS));
  isAdmin$ = this.store.pipe(select(fromUi.selectDroitAdmin));
  isLecteur$ = this.store.pipe(select(fromUi.selectRoleLecteur));
  isGestionnaireCongesDS$ = this.store.pipe(select(fromUi.selectRoleGestionnaireCongesDS));
  isGestionnaireCongesDR$ = this.store.pipe(select(fromUi.selectRoleGestionnaireCongesDR));

  constructor(private store: Store<AppState>) {}
}
