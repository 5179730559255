import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { GererCtsComponent } from './gerer-cts/gerer-cts.component';
import { MonActiviteComponent } from './mon-activite/mon-activite.component';
import { IsSignedInGuard } from './shared/is-signed-in.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'mon-activite/actualites',
    pathMatch: 'full'
  },
  {
    path: 'administration',
    loadChildren: () =>
      import('./administration/administration.module').then(mod => mod.AdministrationModule),
    canActivate: [IsSignedInGuard]
  },
  {
    path: 'renseignement',
    loadChildren: () =>
      import('./page-renseignement/page-renseignement.module').then(mod => mod.PageRenseignementModule),
    canActivate: [IsSignedInGuard]
  },
  {
    path: 'profil',
    loadChildren: () => import('./profil/profil.module').then(mod => mod.ProfilModule),
    canActivate: [IsSignedInGuard]
  },
  {
    path: 'gerer-cts',
    component: GererCtsComponent,
    children: [
      {
        path: '',
        redirectTo: 'gerer-conges',
        pathMatch: 'full'
      },
      /*{
        path: 'actualites',
        loadChildren: () =>
          import('./actualites/actualites.module').then(
            mod => mod.ActualitesModule
          ),
        canActivate: [IsSignedInGuard]
      },*/
      {
        path: 'profil',
        loadChildren: () => import('./profil/profil.module').then(mod => mod.ProfilModule),
        canActivate: [IsSignedInGuard]
      },
      {
        path: 'gerer-conges',
        loadChildren: () =>
          import('./gerer-cts/gerer-conges/gerer-conges.module').then(mod => mod.GererCongesModule),
        canActivate: [IsSignedInGuard]
      },
      {
        path: 'gestion-des-services',
        loadChildren: () =>
          import('./gerer-cts/gestion-des-services/gestion-des-services.module').then(mod => mod.GestionDesServicesModule),
        canActivate: [IsSignedInGuard]
      },
      {
        path: 'gerer-deplacements',
        loadChildren: () =>
          import('./gerer-cts/gerer-deplacements/gerer-deplacements.module').then(
            mod => mod.GererDeplacementsModule
          ),
        canActivate: [IsSignedInGuard]
      },
      {
        path: 'gerer-lettres-de-missions',
        loadChildren: () =>
          import('./gerer-cts/gerer-lettres-de-missions/gerer-lettres-de-missions.module').then(
            mod => mod.GererLettresDeMissionsModule
          ),
        canActivate: [IsSignedInGuard]
      },
      {
        path: 'gerer-carrieres',
        loadChildren: () =>
          import('./gerer-cts/gerer-carrieres/gerer-carrieres.module').then(
            mod => mod.GererCarrieresModule
          ),
        canActivate: [IsSignedInGuard]
      },
      {
        path: 'actualites',
        loadChildren: () =>
          import('./gerer-cts/actualites/actualites.module').then(
            mod => mod.ActualitesCtsModule
          ),
        canActivate: [IsSignedInGuard]
      },
      {
        path: 'gerer-formations',
        loadChildren: () =>
          import('./gerer-cts/gerer-formations/gerer-formations.module').then(
            mod => mod.GererFormationsModule
          ),
        canActivate: [IsSignedInGuard]
      },
      {
        path: 'gerer-rapport',
        loadChildren: () =>
          import('./rapport-activite/rapport-activite.module').then(
            mod => mod.RapportActiviteModule
          ),
        canActivate: [IsSignedInGuard]
      }
    ]
  },
  {
    path: 'mon-activite',
    component: MonActiviteComponent,
    children: [
      {
        path: '',
        redirectTo: 'mes-conges',
        pathMatch: 'full'
      },
      {
        path: 'actualites',
        loadChildren: () =>
          import('./actualites/actualites.module').then(
            mod => mod.ActualitesModule
          ),
        canActivate: [IsSignedInGuard]
      },
      {
        path: 'profil',
        loadChildren: () => import('./profil/profil.module').then(mod => mod.ProfilModule),
        canActivate: [IsSignedInGuard]
      },
      {
        path: 'mes-conges',
        loadChildren: () =>
          import('./mon-activite/mes-conges/mes-conges.module').then(mod => mod.MesCongesModule),
        canActivate: [IsSignedInGuard]
      },
      {
        path: 'mes-deplacements',
        loadChildren: () =>
          import('./mon-activite/mes-deplacements/mes-deplacements.module').then(
            mod => mod.MesDeplacementsModule
          ),
        canActivate: [IsSignedInGuard]
      },
      {
        path: 'mes-lettres-de-missions',
        loadChildren: () =>
          import('./mon-activite/mes-lettres-de-missions/mes-lettres-de-missions.module').then(
            mod => mod.MesLettresDeMissionsModule
          ),
        canActivate: [IsSignedInGuard]
      },
      {
        path: 'mes-services',
        loadChildren: () =>
          import('./mon-activite/mes-services/gestion-des-services.module').then(
            mod => mod.GestionDesServicesMonActiviteModule
          ),
        canActivate: [IsSignedInGuard]
      },
      {
        path: 'mes-formations',
        loadChildren: () =>
          import('./mon-activite/mes-formations/mes-formations.module').then(
            mod => mod.MesFormationsModule
          ),
        canActivate: [IsSignedInGuard]
      },
      {
        path: 'mon-rapport-activite',
        loadChildren: () =>
          import('./rapport-activite/rapport-activite.module').then(
            mod => mod.RapportActiviteModule
          ),
        canActivate: [IsSignedInGuard]
      }

    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'top'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
