import { Injectable } from '@angular/core';
import { switchMap, catchError, map } from 'rxjs/operators';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import * as fromAction from './filtre.actions';
import { forkJoin, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { State } from './filtre.reducer';
import * as fromSelect from './filtre.selectors';
import { Router } from '@angular/router';
import { errorMessage } from 'src/app/reducers/ui/ui.actions';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class FiltreEffects {
  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private http: HttpClient,
    private router: Router
  ) { }

  loadListes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.loadRef),
      switchMap(action => {
        return forkJoin([this.http.get('/api/liste_filtre_onglet')]).pipe(
          map(([result]: any) => {
            return fromAction.loadRefDone({
              affectation: result.data.affectation,
              affectation_admin: result.data.affectation_admin,
              etat: result.data.etat,
              federation: result.data.federation,
              fop: result.data.fop,
              region: result.data.region,
              statut: result.data.statut,
              status_formation: result.data.status_formation
            });
          }),
          catchError(err => {
            console.log({ err });
            return of(errorMessage({ errorMessage: err.message }));
          })
        );
      })
    )
  );
  loadContinent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.loadContinent),
      switchMap(action => {
        return forkJoin([this.http.get('/api/get_liste_continent')]).pipe(
          map(([result]: any) => {
            return fromAction.loadContinentDone({
              continent: result.data
            });
          }),
          catchError(err => {
            console.log({ err });
            return of(errorMessage({ errorMessage: err.message }));
          })
        );
      })
    )
  );
  loadListesProfil$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.loadRefProfil),
      switchMap(action => {
        return forkJoin([this.http.get('/api/liste_qualification_utilisateur_admin')]).pipe(
          map(([result]: any) => {
            return fromAction.loadRefProfilDone({
              profil: [...result.data.nonCTS, ...result.data.CTS.filter(a => a.libelle == "Fédération")]
            });
          }),
          catchError(err => {
            console.log({ err });
            return of(errorMessage({ errorMessage: err.message }));
          })
        );
      })
    )
  );
}
