<div class="modal-content">
  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title">{{ data.titre }}</h4>
  </div>
  <div class="modal-body">
    <p align="center" [innerHtml]="data.message" style="padding: 0 10%"></p>
  </div>
  <div class="modal-footer">
    <button mat-raised-button type="button" (click)="close()" style="display: inline-block; margin-right: 10px">
      Annuler
    </button>
    <button mat-raised-button color="primary" (click)="confirm()">Ok</button>
  </div>
</div>
