import { createSelector } from '@ngrx/store';
import { ROLES } from 'src/app/constant';
import { AppState } from '..';

export const selectFiltre = (state: AppState) => state.filtre;

export const selectForm = createSelector(selectFiltre, state => state.form);

export const selectAffectations = createSelector(selectFiltre, state => state.affectations);
export const selectAffectationsAdmin = createSelector(selectFiltre, state => state.affectation_admin);
export const selectEtats = createSelector(selectFiltre, state => state.etats.filter(c => c.libelle == 'En attente de validation' || c.libelle == 'En attente de signature'));

export const selectEtatsFormation = createSelector(selectFiltre, state => state.status_formation.filter(c => c.libelle == 'En attente de validation'));

export const selectFederations = createSelector(selectFiltre, state => state.federations);

export const selectFops = createSelector(selectFiltre, state => state.fops);

export const selectProfils = createSelector(selectFiltre, state => state.profils);

export const selectRegions = createSelector(selectFiltre, state => state.regions.filter(c => c.libelle == 'Om UE' || c.libelle == 'Om international'));

export const selectStatuts = createSelector(selectFiltre, state => state.statuts);

export const selectContinents = createSelector(selectFiltre, state => state.continent);

export const selectFiltreValues = createSelector(selectFiltre, state => {
  return {
    ...state.form.value,
    libellePoste: state.fops.find(c => c.idPoste == state.form.value.poste)?.libellePoste,
    libelleProfil: state.profils.find(c => c.id == state.form.value.profil)?.libelle,
    libelleFederation: state.federations.find(c => c.idFederation == state.form.value.federation)?.rSerLibl,
    libelleAffectation: state.affectations.find(c => c.idAffectation == state.form.value.affectation)?.libelleAffectation,
    libelleRegion: state.regions.find(c => c.id == state.form.value.region)?.libelle,
    libelleAffectation_admin: state.affectation_admin.find(c => c.idAffectation == state.form.value.affectation_admin)?.idAffectation,

  };
});

export const selectFiltreValuesRAA = createSelector(selectFiltre, state => {
  return {
    ...state.form.value,
    libellePoste: state.fops.find(c => c.idPoste == state.form.value.poste)?.libellePoste,
    libelleProfil: state.profils.find(c => c.id == state.form.value.profil)?.libelle,
    libelleFederation: state.federations.find(c => c.idFederation == state.form.value.federation)?.rSerLibl,
    libelleAffectation: state.affectations.find(c => c.idAffectation == state.form.value.affectation)?.libelleAffectation,
    libelleRegion: state.regions.find(c => c.id == state.form.value.region)?.libelle,
    libelleAffectation_admin: state.affectation_admin.find(c => c.idAffectation == state.form.value.affectation_admin)?.idAffectation,
    statusRaa: state.form.value.status_raa

  };
});


export const selectOpened = createSelector(selectFiltre, state => state.opened);

export const selectFiltreAC = createSelector(selectFiltre, state => state.filtreAC);
