<nav class="dashboard-icons pdf_non" *ngIf="showDashboard$ | async">
  <div class="container">
    <div class="big-icons row">
      <div class="col-12 navMenu">
        <div
          class="navBtn"
          [routerLink]="['/mon-activite/profil']"
          routerLinkActive="active"
          *ngIf="(affichActivite$ | async) === false"
        >
          <span class="text">Ma carrière</span>
        </div>
        <div
          class="navBtn"
          [routerLink]="['/mon-activite/mes-deplacements']"
          routerLinkActive="active"
          *ngIf="(affichActivite$ | async) === false"
        >
          <span class="text">Mes déplacements</span>
          <span *ngIf="(pastilles$ | async).pastilleDeplacements as pastille" class="badgeNav">{{ pastille }}</span>
        </div>
        <div
          class="navBtn"
          [routerLink]="['/mon-activite/mes-conges']"
          routerLinkActive="active"
          *ngIf="(affichActivite$ | async) === false"
        >
          <span class="text">Mes congés</span>
          <span *ngIf="(pastilles$ | async).pastilleConges as pastille" class="badgeNav">{{ pastille }}</span>
        </div>
        <div
          class="navBtn"
          [routerLink]="
            (affichActivite$ | async) ? ['/gerer-cts/actualites/liste-actualites'] : ['/mon-activite/actualites']
          "
          routerLinkActive="active"
        >
          <span class="text">Mes actualités</span>
          <span *ngIf="(pastilles$ | async).pastilleActualites as pastille" class="badgeNav">{{ pastille }}</span>
        </div>
        <div
          class="navBtn"
          [routerLink]="['/mon-activite/mes-lettres-de-missions']"
          routerLinkActive="active"
          *ngIf="(affichActivite$ | async) === false"
        >
          <span class="text">Mes lettres de missions</span>
          <span *ngIf="(pastilles$ | async).pastilleLettresDeMissions as pastille" class="badgeNav">
            {{ pastille }}
          </span>
        </div>
        <div
          class="navBtn"
          [routerLink]="['/mon-activite/mes-formations']"
          routerLinkActive="active"
          *ngIf="(affichActivite$ | async) === false"
        >
          <span class="text">Mes formations</span>
          <span *ngIf="(pastilles$ | async)?.pastilleFormations as pastille" class="badgeNav">
            {{ pastille }}
          </span>
        </div>

        <div class="navBtn" *ngIf="(affichActivite$ | async) === false">
          <span class="text" [routerLink]="['/mon-activite/mes-services']" routerLinkActive="active">Mes services</span>
          <span *ngIf="(pastilles$ | async)?.pastillesServices as pastille" class="badgeNav">
            {{ pastille }}
          </span>
        </div>
      </div>
    </div>
  </div>
</nav>

<div class="container">
  <router-outlet></router-outlet>
</div>
