import { NativeDateAdapter, MatDateFormats } from '@angular/material/core';

export const MY_FORMAT: MatDateFormats = {
  parse: {
    dateInput: ['DD/MM/YYYY']
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};

export class AppDateAdapter extends NativeDateAdapter {
  format(date: Date, displayFormat: any): string {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return (
      day.toString().padStart(2, '0')
      + '/'
      + month.toString().padStart(2, '0')
      + '/'
      + year.toString().padStart(4, '0')
    );
  }
  parse(value: any): Date | null {
    if (value == null || value === '') {
      return null;
    }
    if (typeof value === 'string') {
      const match1 = value.match(/(\d{2})\/(\d{2})\/(\d{4})/);
      if (match1) {
        return new Date(
          parseInt(match1[3], 10),
          parseInt(match1[2], 10) - 1,
          parseInt(match1[1], 10),
          0,
          0,
          0
        );
      }
      const match2 = value.match(/(\d{2})(\d{2})(\d{4})/);
      if (match2) {
        return new Date(
          parseInt(match2[3], 10),
          parseInt(match2[2], 10) - 1,
          parseInt(match2[1], 10),
          0,
          0,
          0
        );
      }
    }
    if (value instanceof Date) {
      return value;
    }
    return null;
  }
}
