import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable()
export class CheckSessionService {
  constructor(private http: HttpClient) {}
  test(): Promise<boolean> {
    return this.http
      .post('/api/profil', {})
      .pipe(
        map(c => true),
        catchError(err => {
          console.log(err);
          document.location.href = '/login';
          return of(false);
        })
      )
      .toPromise();
  }
}
