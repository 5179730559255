import { Component, ChangeDetectionStrategy } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as fromUi from '../reducers/ui';
import { AppState } from '../reducers';

@Component({
  selector: 'app-mon-activite',
  templateUrl: './mon-activite.component.html',
  styleUrls: ['./mon-activite.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MonActiviteComponent {
  pastilles$ = this.store.pipe(select(fromUi.selectPastillesGA));
  showDashboard$ = this.store.pipe(select(fromUi.selectShowDashboard));
  affichActivite$ = this.store.pipe(select(fromUi.selectDroitAffichActivité));
  constructor(private store: Store<AppState>) { }
}
