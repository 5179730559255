import { createAction, props, Action } from '@ngrx/store';
import {
  RefAffectationController,
  RefContinent,
  RefEtat,
  RefFederation,
  RefFop,
  RefProfil,
  RefRegion,
  RefStatusFormation,
  RefStatut
} from './filtre.reducer';

export const loadRef = createAction('[FILTRE] Load Ref');

export const loadRefDone = createAction(
  '[FILTRE] Load Ref Done',
  props<{
    affectation: RefAffectationController[];
    affectation_admin: RefAffectationController[];
    etat: RefEtat[];
    federation: RefFederation[];
    fop: RefFop[];
    region: RefRegion[];
    statut: RefStatut[];
    status_formation: RefStatusFormation[];
  }>()
);
export const loadRefProfil = createAction('[FILTRE] Load Ref Profil');

export const loadRefProfilDone = createAction(
  '[FILTRE] load Ref Profil Done',
  props<{
    profil: RefProfil[];
  }>()
);

export const loadContinent = createAction('[FILTRE] Load continent');
export const loadContinentDone = createAction(
  '[FILTRE] Load continent Done',
  props<{
    continent: RefContinent[];
  }>()
);
export const openClose = createAction('[FILTRE] openClose');

export const filtreAC = createAction('[FILTRE] filtreAC');
export const resetFiltre = createAction('[FILTRE] resetFiltre');

export const loadFiltreACDone = createAction(
  '[FILTRE] loadFiltreACDone',
  props<{
    filtreAC: boolean;
  }>()
);
