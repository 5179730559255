<div class="filters">
  <div class="row">
    <div class="col-sm-12 col-md-12 col-lg-8 col-xs-12 nopadding">
      <button
        class="button button-large align-left filter-btn hidden-xs"
        [ngClass]="{ 'filter-btn-top': filtreOpened$ | async, 'filter-btn': !(filtreOpened$ | async) }"
        type="button"
        (click)="openCloseFiltre()"
      >
        Filtrer
      </button>
      <div class="collapse in" id="collapseFilter" *ngIf="filtreOpened$ | async">
        <form class="filter" [ngrxFormState]="form$ | async">
          <div class="row">
            <div class="col-sm-4 col-md-4 col-lg-4 form-group">
              <select class="form-control" [ngrxFormControlState]="(form$ | async).controls.gender">
                <option [value]="null">Civilité</option>
                <option value="M.">M.</option>
                <option value="Mme">Mme</option>
              </select>
            </div>
            <div class="col-sm-4 col-md-4 col-lg-4 form-group">
              <input
                type="text"
                [ngrxFormControlState]="(form$ | async).controls.firstName"
                placeholder="Prénom"
                class="form-control"
              />
            </div>
            <div class="col-sm-4 col-md-4 col-lg-4 form-group">
              <input
                type="text"
                [ngrxFormControlState]="(form$ | async).controls.lastName"
                placeholder="Nom"
                class="form-control"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-sm-4 col-md-4 col-lg-4 form-group" *ngIf="showAffectation">
              <select class="form-control" [ngrxFormControlState]="(form$ | async).controls.affectation">
                <option [value]="null" style="font-style: italic" selected="selected">Toute affectation</option>
                <option *ngFor="let item of affectations$ | async" [value]="item.idAffectation">
                  {{ item.libelleLong }}
                </option>
              </select>
            </div>
            <div class="col-sm-4 col-md-4 col-lg-4 form-group" *ngIf="showAffectationAdmin">
              <select class="form-control" [ngrxFormControlState]="(form$ | async).controls.affectation_admin">
                <option [value]="null" style="font-style: italic" selected="selected">Toute affectation</option>
                <option *ngFor="let item of affectationsAdmin$ | async" [value]="item.idAffectation">
                  {{ item.libelleLong }}
                </option>
              </select>
            </div>
            <div class="col-sm-4 col-md-4 col-lg-4 form-group" *ngIf="showFederation">
              <select class="form-control" [ngrxFormControlState]="(form$ | async).controls.federation">
                <option [value]="null" style="font-style: italic" selected="selected">Toute fédération</option>
                <option *ngFor="let item of federations$ | async" [value]="item.idFederation">
                  {{ item.rSerLibl }}
                </option>
              </select>
            </div>

            <div class="col-sm-4 col-md-4 col-lg-4 form-group" *ngIf="showPoste">
              <select class="form-control" [ngrxFormControlState]="(form$ | async).controls.poste">
                <option [value]="null" style="font-style: italic" selected="selected">Poste</option>
                <option *ngFor="let item of postes$ | async" [value]="item.idPoste">
                  {{ item.libellePoste }}
                </option>
              </select>
            </div>

            <div class="col-sm-4 col-md-4 col-lg-4 form-group" *ngIf="showProfil">
              <select class="form-control" [ngrxFormControlState]="(form$ | async).controls.profil">
                <option [value]="null" style="font-style: italic" selected="selected">Profil</option>
                <option *ngFor="let item of profils$ | async" [value]="item.id">
                  {{ item.libelle }}
                </option>
              </select>
            </div>

            <div class="col-sm-4 col-md-4 col-lg-4 form-group" *ngIf="showEtat">
              <select class="form-control" [ngrxFormControlState]="(form$ | async).controls.etat">
                <option [value]="null" style="font-style: italic" selected="selected">Etat</option>
                <option *ngFor="let item of etats$ | async" [value]="item.id">
                  {{ item.libelle }}
                </option>
              </select>
            </div>

            <div class="col-sm-4 col-md-4 col-lg-4 form-group" *ngIf="showEtatActif">
              <select class="form-control" [ngrxFormControlState]="(form$ | async).controls.etatActif">
                <option [value]="null" style="font-style: italic" selected="selected">Etat</option>
                <option [value]="true">Actif</option>
                <option [value]="false">Inactif</option>
              </select>
            </div>

            <div class="col-sm-4 col-md-4 col-lg-4 form-group" *ngIf="showStatuts">
              <select class="form-control" [ngrxFormControlState]="(form$ | async).controls.statut">
                <option [value]="null" style="font-style: italic" selected="selected">Statuts</option>
                <option *ngFor="let item of statuts$ | async" [value]="item.libelle">
                  {{ item.libelle }}
                </option>
              </select>
            </div>

            <div class="col-sm-4 col-md-4 col-lg-4 form-group" *ngIf="showYears">
              <select class="form-control" [ngrxFormControlState]="(form$ | async).controls.annee">
                <option [value]="null" style="font-style: italic" selected="selected">Année</option>
                <option *ngFor="let year of years" [value]="year">
                  {{ year }}
                </option>
              </select>
            </div>

            <div class="col-sm-4 col-md-4 col-lg-4 form-group" *ngIf="showStatutsRaa">
              <select class="form-control" [ngrxFormControlState]="(form$ | async).controls.status_raa" >
                <option [value]="null" style="font-style: italic">Statut Raa</option>
                <option value="pending" style="font-style: italic">En Attente</option>
                <option value="submitted" style="font-style: italic">Déposé</option>
              </select>
            </div>
            
            
            <div class="col-sm-4 col-md-4 col-lg-4 form-group">
              <input
                *ngIf="showLiebellePays"
                type="text"
                [ngrxFormControlState]="(form$ | async).controls.libellePays"
                placeholder="Libelle pays"
                class="form-control"
              />
            </div>
            <div class="col-sm-4 col-md-4 col-lg-4 form-group" *ngIf="showContinents">
              <select class="form-control" [ngrxFormControlState]="(form$ | async).controls.continent">
                <option [value]="null" style="font-style: italic" selected="selected">Continents</option>
                <option *ngFor="let item of continent$ | async" [value]="item.id">
                  {{ item.libelle }}
                </option>
              </select>
            </div>
            <div class="col-sm-4 col-md-4 col-lg-4 form-group" *ngIf="showUEMembre">
              <div class="checkbox-form">
                <input
                  ng-model="DS"
                  id="checkA"
                  type="checkbox"
                  [ngrxFormControlState]="(form$ | async).controls.ue"
                  ng-click="filtrerActivites()"
                />
                <label for="checkA" class="label-form">
                  &nbsp;
                  <strong>Union Européenne</strong>
                </label>
              </div>
            </div>
            <div class="col-sm-6 col-md-6 col-lg-6 form-group region" style="margin-top: -16px" *ngIf="showRegion">
              <select class="form-control" [ngrxFormControlState]="(form$ | async).controls.region">
                <option [value]="null" style="font-style: italic" selected="selected">Zone internationale</option>
                <option *ngFor="let item of regions$ | async" [value]="item.id">
                  {{ item.libelle }}
                </option>
              </select>
            </div>

            <div class="col-sm-6 col-md-6 col-lg-6 form-group" *ngIf="showDate">
              <input
                [ngrxFormControlState]="(form$ | async).controls.dateDebut"
                [ngrxValueConverter]="dateValueConverter"
                bsDatepicker
                name="dateDebut"
                type="text"
                placeholder="Date de début"
                class="form-control date-datepiker"
                autocomplete="off"
              />
            </div>
            <div class="col-sm-6 col-md-6 col-lg-6 form-group" *ngIf="showDateDep">
              <input
                [ngrxFormControlState]="(form$ | async).controls.dateDep"
                [ngrxValueConverter]="dateValueConverter"
                bsDatepicker
                name="date"
                type="text"
                placeholder="Date"
                class="form-control date-datepiker"
                autocomplete="off"
              />
            </div>
            <div class="col-sm-6 col-md-6 col-lg-6 form-group" *ngIf="showDate">
              <input
                [ngrxFormControlState]="(form$ | async).controls.dateFin"
                [ngrxValueConverter]="dateValueConverter"
                bsDatepicker
                name="dateFin"
                type="text"
                placeholder="Date de fin"
                class="form-control date-datepiker"
                autocomplete="off"
              />
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="col-sm-2 col-md-2 col-lg-2" *ngIf="showBtnFiltreAC">
      <button
        class="button btn-filterAc"
        [ngClass]="boolfiltre === true ? 'filtreRed' : ''"
        (click)="changeFiltreAC((boolfiltre = !boolfiltre))"
      >
        Filtrer
        <strong>AC</strong>
      </button>
    </div>
    <div class="col-sm-2 col-md-2 col-lg-2" *ngIf="showBtnAnnuler">
      <a href="#" class="button btn-filterExport" (click)="onClickBtnAnnuler()" style="background-color: red">
        Annuler
      </a>
    </div>
    <div class="col-sm-2 col-md-2 col-lg-2" *ngIf="showBtnExport">
      <a href="#" class="button btn-filterExport" (click)="onClickBtnExport()">
        <i class="icon-export-small"></i>
        Excel
      </a>
    </div>
    <ng-content></ng-content>
  </div>
</div>
