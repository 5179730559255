import { ActionReducerMap, MetaReducer } from '@ngrx/store';

import * as fromUi from './ui/ui.reducer';
import * as fromFiltre from './filtre/filtre.reducer';
import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import { RouterStateUrl } from '../shared/custom-route-serializer';

export interface AppState {
  ui: fromUi.State;
  filtre: fromFiltre.State;
  router: RouterReducerState<RouterStateUrl>;
}

export const reducers: ActionReducerMap<AppState> = {
  ui: fromUi.reducer,
  filtre: fromFiltre.reducer,
  router: routerReducer
};

export const metaReducers: MetaReducer<AppState>[] = [];
