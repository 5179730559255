import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import {
  confirmationDialog,
  errorMessage,
  clearErrorMessage,
  infoMessage,
  loadProfil,
  loadProfilDone,
  loadVersionDone,
  loadVersion,
  loadPastilles,
  loadPastillesDone,
  noAction,
  logout,
  loadAllAide,
  loadAllAideDone,
  openAllAide,
  getStaticText,
  getStaticTextDone,
  getStaticTextFormationDone,
  getCalendrierFormInfo,
  getCalendrierFormInfoDone
} from './ui.actions';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';
import { MessageDialogComponent } from 'src/app/shared/message-dialog/message-dialog.component';
import { ModalAideNoticesComponent } from 'src/app/modal-aide-notices/modal-aide-notices.component';
import { Router } from '@angular/router';

@Injectable()
export class UiEffects {
  // ngrxOnInitEffects(): Action {
  //   return getApiVersion();
  // }

  confirmationDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(confirmationDialog),
      mergeMap(action => {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
          id: 'ConfirmationDialog',
          width: '500px',
          panelClass: 'mat-dialog-container-no-padding',
          data: {
            message: action.message,
            titre: action.titre
          }
        });
        return dialogRef.afterClosed().pipe(
          tap(() => {
            const dlg = this.dialog.getDialogById('ConfirmationDialog');
            if (dlg != null) {
              dlg.close();
            }
          }),
          map((result: { action: string; context: any }) => {
            if (result.action === 'confirm') {
              return action.confirmAction;
            } else {
              return action.cancelAction;
            }
          })
        );
      })
    )
  );

  // infoMessage$ = createEffect(
  //   () =>
  //     this.actions$.pipe(
  //       ofType(infoMessage),
  //       tap(action => {
  //         this.snackBar.open(action.message, null, {
  //           duration: action.duration ?? 5000
  //         });
  //       })
  //     ),
  //   { dispatch: false }
  // );

  infoMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(infoMessage),
      mergeMap(action => {
        const dialogRef = this.dialog.open(MessageDialogComponent, {
          id: 'MessageDialog',
          width: '500px',
          panelClass: 'mat-dialog-container-no-padding',
          data: {
            message: action.message,
            titre: 'INFORMATION :'
          }
        });
        return dialogRef.afterClosed().pipe(
          tap(() => {
            const dlg = this.dialog.getDialogById('MessageDialog');
            if (dlg != null) {
              dlg.close();
            }
          }),
          map((result: { action: string; context: any }) => noAction())
        );
      })
    )
  );

  errorMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(errorMessage),
      mergeMap(action => {
        const dialogRef = this.dialog.open(MessageDialogComponent, {
          id: 'MessageErrorDialog',
          width: '500px',
          panelClass: 'mat-dialog-container-no-padding',
          data: {
            message: action.errorMessage,
            titre: 'Erreur'
          }
        });
        return dialogRef.afterClosed().pipe(
          tap(() => {
            const dlg = this.dialog.getDialogById('MessageErrorDialog');
            if (dlg != null) {
              dlg.close();
            }
          }),
          map((result: { action: string; context: any }) => noAction())
        );
      })
    )
  );

  clearErrorMessage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(clearErrorMessage),
        tap(() => {
          this.snackBar.dismiss();
        })
      ),
    { dispatch: false }
  );

  doLoadProfil$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadProfil),
      switchMap(action =>
        this.http.post('/api/profil', {}).pipe(
          tap((result: any) => {
            function isNullOrWhitespace(input) {
              return !input || !input.trim();
            }
            if (
              isNullOrWhitespace(result.data.courriel) ||
              isNullOrWhitespace(result.data.adresse) ||
              (isNullOrWhitespace(result.data.telephoneBureau) &&
                isNullOrWhitespace(result.data.telephonePortable))
            ) {
              this.router.navigate(['/renseignement']);
            }
          }),
          map((result: any) =>
            loadProfilDone({
              idLieu: result.data.idLieu,
              idUtilisateur: result.data.idUtilisateur,
              civilite: result.data.civilite,
              id_profil: result.data.id_profil,
              nom: result.data.nom,
              mail: result.data.mail,
              prenom: result.data.prenom,
              dateDeNaissance: result.data.dateDeNaissance,
              courriel: result.data.courriel,
              telephoneBureau: result.data.telephoneBureau,
              telephonePortable: result.data.telephonePortable,
              adresse: result.data.adresse,
              login: result.data.login,
              pCivMatcle: result.data.pCivMatcle,
              idPoste: result.data.idPoste,
              libellePoste: result.data.libellePoste,
              fonction: result.data.fonction,
              grade: result.data.grade,
              indiceMajore: result.data.indiceMajore,
              dateFinDroits: result.data.dateFinDroits,
              libelleFopComp: result.data.libelleFopComp,
              libelleFop: result.data.libelleFop,
              libelleAffectation: result.data.libelleAffectation,
              affectationLong: result.data.affectationLong,
              rSerLibl: result.data.rSerLibl,
              idAffectation: result.data.idAffectation,
              token: result.data.token,
              idOdmCadreGeneral: result.data.idOdmCadreGeneral,
              libelleOdmCadreGeneral: result.data.libelleOdmCadreGeneral,
              idFederation: result.data.idFederation,
              saisonSportiveFederation: result.data.saisonSportiveFederation,
              libelleRole: result.data.libelleRole,
              droitRefFederation: result.data.droitRefFederation,
              path_scan: result.data.path_scan,
              id_scan: result.data.id_scan,
              redirectUrl: action.redirectUrl
            })
          ),
          catchError(err => {
            console.log({ err });
            return of(errorMessage({ errorMessage: err.message }));
          })
        )
      )
    )
  );

  loadProfilDone$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loadProfilDone),
        tap(action => {
          if (action.redirectUrl != null) {
            this.router.navigate([action.redirectUrl]);
          }
        })
      ),
    { dispatch: false }
  );

  doLoadVersion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadVersion),
      switchMap(action => {
        return this.http.post('/api/menu', {}).pipe(
          map((result: any) =>
            loadVersionDone({
              version: result[3]
            })
          ),
          catchError(err => {
            console.log({ err });
            return of(errorMessage({ errorMessage: err.message }));
          })
        );
      })
    )
  );

  doLoadPastilles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadPastilles),
      switchMap(action => {
        return this.http.post('/api/pastilles/ga', {}).pipe(
          mergeMap((resultGA: any) => {
            return this.http.post('/api/pastilles/gcts', {}).pipe(
              map((resultGCTS: any) =>
                loadPastillesDone({
                  pastilleDeplacementsGA: resultGA.data.GA.ODM,
                  pastilleActualitesGA: resultGA.data.GA.ACTU,
                  pastilleLettresDeMissionsGA: resultGA.data.GA.LM,
                  pastilleCongesGA: resultGA.data.GA.conges,
                  pastilleDeplacementsGCTS: resultGCTS.data.CTS.ODM,
                  pastilleActualitesGCTS: resultGCTS.data.CTS.ACTU,
                  pastilleLettresDeMissionsGCTS: resultGCTS.data.CTS.LM,
                  pastilleCongesGCTS: resultGCTS.data.CTS.conges,
                  pastilleFormationGCTS: resultGCTS.data.CTS.formations,
                  pastilleFormationGA: resultGA.data.GA.formations,
                  pastillesServicesGA: resultGA.data.GA.services,
                  pastillesServicesGCTS: resultGCTS.data.CTS.services,
                })
              )
            );
          }),
          catchError(err => {
            console.log({ err });
            return of(errorMessage({ errorMessage: err.message }));
          })
        );
      })
    )
  );

  logout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(logout),
        tap(() => {
          localStorage.clear();
          sessionStorage.clear();
          this.http
            .get('/logout')
            .pipe(catchError(err => of(null)))
            .subscribe(c => {
              document.location.href = '/login';
            });
        })
      ),
    { dispatch: false }
  );

  loadAllAide$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadAllAide),
      switchMap(action => {
        return this.http.post('/api/get_all_aide', {}).pipe(
          map((result: any) =>
            loadAllAideDone({
              data: result.data.map(i => ({
                ...i,
                description:
                  i.type == 'cts'
                    ? 'Guide général: CTS'
                    : i.type == 'dtn'
                      ? 'Guide général: DTN'
                      : i.type == 'dr'
                        ? 'Guide général: DR'
                        : i.type == 'odm'
                          ? 'Fiche pratique: Déplacements'
                          : i.type == 'lm'
                            ? 'Fiche pratique: Lettre de missions'
                            : i.type == 'agenda'
                              ? 'Fiche pratique: Rapport activité'
                              : i.type == 'restit'
                                ? 'Fiche pratique: Restitution'
                                : i.type == 'conge'
                                  ? 'Fiche pratique: Congés'
                                  : i.type == 'sit'
                                    ? 'Fiche pratique: Site'
                                    : i.type == 'ser'
                                      ? 'Fiche pratique: Service'
                                      : i.type == 'car'
                                        ? 'Fiche pratique: Carrière'
                                        : i.type == 'login'
                                          ? 'Fiche pratique: Login'
                                          : i.type == 'act'
                                            ? 'Fiche pratique: Actualités'
                                            : i.type == 'form'
                                              ? 'Fiche pratique: Formation'
                                              : i.type == 'form'
              }))
            })
          ),
          catchError(err => {
            console.log({ err });
            return of(errorMessage({ errorMessage: err.message }));
          })
        );
      })
    )
  );

  infoMessage$2 = createEffect(() =>
    this.actions$.pipe(
      ofType(openAllAide),
      mergeMap(action => {
        const dialogRef = this.dialog.open(ModalAideNoticesComponent, {
          id: 'idModalAideNoticesComponent',
          width: '750px',
          panelClass: 'mat-dialog-container-no-padding',
          data: {}
        });
        return dialogRef.afterClosed().pipe(
          tap(() => {
            const dlg = this.dialog.getDialogById('idModalAideNoticesComponent');
            if (dlg != null) {
              dlg.close();
            }
          }),
          map((result: { action: string; context: any }) => noAction())
        );
      })
    )
  );
  doGetCalendrierScolaire$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getCalendrierFormInfo),
      switchMap(action => {
        return this.http.get('/api/calendrier-conges-info', {}).pipe(
          map((result: any) =>
            getCalendrierFormInfoDone({
              calendrier_scolaire: result.data.calendrier_scolaire

            })
          ),
          catchError(err => {
            console.log({ err });
            return of(errorMessage({ errorMessage: err.message }));
          })
        );
      })
    )
  );
  loadLettresText$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getStaticText),
      switchMap(action => {
        return this.http
          .post('/api/get_parametrage_texte', [
            'ACCUEIL_INFO',
            'COURRIEL_ASSISTANCE_INFO',
            'FORMATION_ATTENTION_1',
            'FORMATION_ATTENTION_2',
            'FORMATION_COMMENTAIRE_1',
            'FORMATION_COMMENTAIRE_2',
            'FORMATION_COMMENTAIRE_3'
          ])
          .pipe(
            switchMap((result: any) => {
              return of(
                getStaticTextDone({
                  staticText: result.data['ACCUEIL_INFO'],
                  emailAssistance: { email: result.data.COURRIEL_ASSISTANCE_INFO }
                }),
                getStaticTextFormationDone({
                  staticTextFormation: {
                    textAttention1: result.data['FORMATION_ATTENTION_1'],
                    textAttention2: result.data['FORMATION_ATTENTION_2'],
                    textCommentaire1: result.data['FORMATION_COMMENTAIRE_1'],
                    textCommentaire2: result.data['FORMATION_COMMENTAIRE_2'],
                    textCommentaire3: result.data['FORMATION_COMMENTAIRE_3']
                  }
                })
              );
            }),
            catchError(err => {
              console.log({ err });
              return of(errorMessage({ errorMessage: err.message }));
            })
          );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private http: HttpClient,
    private router: Router
  ) { }
}
