<div class="modal-content">
  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title">{{ data.titre }}</h4>
  </div>
  <div class="modal-body">
    <p align="center" [innerHtml]="data.message" style="padding: 0 10%"></p>
  </div>
  <div class="modal-footer">
    <button type="button" class="button button-cancel" (click)="close()">Fermer</button>
  </div>
</div>
