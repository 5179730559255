import { Component, ChangeDetectionStrategy, Input, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as fromUi from '../../reducers/ui';
import * as fromFiltre from '../../reducers/filtre';
import { AppState } from '../../reducers';
import { of } from 'rxjs';
import { NgrxValueConverters } from 'ngrx-forms';
import { getYear } from 'date-fns';
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-filtre',
  templateUrl: './filtre.component.html',
  styleUrls: ['./filtre.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})


export class FiltreComponent implements OnInit {
  form$ = this.store.pipe(select(fromFiltre.selectForm));
  affectations$ = this.store.pipe(select(fromFiltre.selectAffectations));
  affectationsAdmin$ = this.store.pipe(select(fromFiltre.selectAffectationsAdmin));
  federations$ = this.store.pipe(select(fromFiltre.selectFederations));
  postes$ = this.store.pipe(select(fromFiltre.selectFops));
  profils$ = this.store.pipe(select(fromFiltre.selectProfils));
  etats$ = this.store.pipe(select(fromFiltre.selectEtats));
  regions$ = this.store.pipe(select(fromFiltre.selectRegions));
  filtreOpened$ = this.store.pipe(select(fromFiltre.selectOpened));
  statuts$ = this.store.pipe(select(fromFiltre.selectStatuts));
  dateValueConverter = NgrxValueConverters.dateToISOString;
  continent$ = this.store.pipe(select(fromFiltre.selectContinents));

  boolfiltre = false;

  @Input()
  showDate: boolean;
  @Input()
  showDateDep: boolean;
  @Input()
  showRegion: boolean;

  @Input()
  showEtat: boolean;

  @Input()
  showFederation: boolean;

  @Input()
  showAffectation: boolean;

  @Input()
  showAffectationAdmin: boolean;
  @Input()
  showBtnFiltreAC: boolean;

  @Input()
  showBtnAnnuler: boolean;

  @Input()
  showBtnExport: boolean;
  @Input()
  showStatuts: boolean;
  @Input()
  showYears: boolean;
  @Input()
  showStatutsRaa: boolean;

   
  @Input()
  showContinents: boolean;
  @Input()
  showLiebellePays: boolean;
  @Input()
  showUEMembre: boolean;
  @Input()
  showPoste: boolean;
  @Input()
  showProfil: boolean;
  @Input()
  showEtatActif: boolean;

  statusValue = null;
  years: number[] = [];


  constructor(private store: Store<AppState>,
    private fb: FormBuilder) { }

  ngOnInit(): void {
    this.store.dispatch(fromFiltre.loadRef());
    this.store.dispatch(fromFiltre.loadRefProfil());
    this.store.dispatch(fromFiltre.loadContinent());

        // Generate a list of years
        const currentYear = getYear(new Date());
        const startYear = 2023; // Change this to adjust the range of years if needed
        for (let year = startYear; year <= currentYear; year++) {
          this.years.push(year);
        }
    

      }      

  

  openCloseFiltre() {
    this.store.dispatch(fromFiltre.openClose());
  }

  onClickBtnAnnuler() { }

  onClickBtnExport() { }

  onClickBtnFiltrerAC() { }

  changeFiltreAC(filtreAC: boolean) {
    this.store.dispatch(fromFiltre.loadFiltreACDone({ filtreAC }));
  }
}
