<section id="Error">
  <h1 class="waitingTitle">Traitement en cours ...</h1>
  <div class="svgPos"></div>
  <div class="spinner"></div>
</section>
<div class="container">
  <div class="row">
    <div class="clock">
      <div class="cup top">
        <div class="sand"></div>
      </div>
      <div class="cup">
        <div class="sand delayed"></div>
      </div>
    </div>
  </div>
</div>
