export class ROLES {
  /**ROLE_DS_ADMIN = 1 */
  public static ROLE_DS_ADMIN = 1;
  /**ROLE_CTS = 2 */
  public static ROLE_CTS = 2;
  /**ROLE_DS_INVITE = 3 */
  public static ROLE_DS_INVITE = 3;
  /**ROLE_DR = 4 */
  public static ROLE_DR = 4;
  /**ROLE_FEDE = 5 */
  public static ROLE_FEDE = 5;
  /**ROLE_FEDE_INVITE = 8 */
  public static ROLE_FEDE_INVITE = 8;
  /**ROLE_DS_SIGNATAIRE = 10 */
  public static ROLE_DS_SIGNATAIRE = 10;
  /**ROLE_DR_SIGNATAIRE = 11 */
  public static ROLE_DR_SIGNATAIRE = 11;
  /**ROLE_LECTEUR = 16 */
  public static ROLE_LECTEUR = 16;
  /**Gestionnaire de Congés à la Direction Sport = 17 */
  public static ROLE_GCS = 17;
  /**Gestionnaire de Congés à la Direction Régionale = 18 */
  public static ROLE_GCR = 18;
}

export class MENU {
  /**ODM = 1 */
  public static ODM = 1;
  /**PROFIL = 2 */
  public static PROFIL = 2;
  /**CONGES = 3 */
  public static CONGES = 3;
  /**ACTUS = 4 */
  public static ACTUS = 4;
  /**LM = 5 */
  public static LM = 5;
}
export class WORKFLOW {
  /**LM_CREATION = 1 */
  public static LM_CREATION = 1;
  /**LM_REDACTION_DTN = 2 */
  public static LM_REDACTION_DTN = 2;
  /**LM_EXAMEN_CTS = 3 */
  public static LM_EXAMEN_CTS = 3;
  /**LM_MODIF_DTN = 4 */
  public static LM_MODIF_DTN = 4;
  /**LM_MODIF_SUP = 5 */
  public static LM_MODIF_SUP = 5;
  /**LM_ATTENTE_SIG = 6 */
  public static LM_ATTENTE_SIG = 6;
  /**LM_SIGNEE = 7 */
  public static LM_SIGNEE = 7;
  /**LM_ANNULATION = 8 */
  public static LM_ANNULATION = 8;
  /**LM_REDACTION_DS = 14 */
  public static LM_REDACTION_DS = 14;
  /**OM_DEMANDER = 9 */
  public static OM_DEMANDER = 9;
  /**OM_ATTENTE_VALIDATION = 10 */
  public static OM_ATTENTE_VALIDATION = 10;
  /**OM_ATTENTE_SIGNATURE = 11 */
  public static OM_ATTENTE_SIGNATURE = 11;
  /**OM_SIGNEE = 12 */
  public static OM_SIGNEE = 12;
  /**OM_ANNULATION = 13 */
  public static OM_ANNULATION = 13;
  /**OM_REFUSE = 15 */
  public static OM_REFUSE = 15;
  /**CO_WAITING = 16 */
  public static CO_WAITING = 16;
  /**CO_ACCEPTED = 17 */
  public static CO_ACCEPTED = 17;
  /**CO_REJECT = 18 */
  public static CO_REJECT = 18;
  /**CO_ACCEPTED_BY_DTN = 19 */
  public static CO_ACCEPTED_BY_DTN = 19;
  /**CO_DECLINED_BY_DTN = 20 */
  public static CO_DECLINED_BY_DTN = 20;
  /**CO_SIGN = 21 */
  public static CO_SIGN = 21;
  /**FO_EN_ATTENTE_DE_VALIDATION= 23 */
  public static FO_EN_ATTENTE_DE_VALIDATION = 23;

  /**FO_ACCEPTE_BY_DTN   = 24*/
  public static FO_ACCEPTE_BY_DTN = 24;
  /** FO_REFUSE_BY_DTN   = 25 */
  public static FO_REFUSE_BY_DTN = 25;
  /** FO_EN_ATTENTE_SIGNATURE = 26*/
  public static FO_EN_ATTENTE_SIGNATURE = 26;
  /** FO_SIGNE = 27 */
  public static FO_SIGNE = 27;
  /** FO_REFUSE = 28*/
  public static FO_REFUSE = 28;
  /** FO_ANNULATION = 29 */
  public static FO_ANNULATION = 29;

  /**RE_WAITING = 30 */
  public static RE_WAITING = 30;
  /**RE_SIGN = 31 */
  public static RE_SIGN = 31;
  /**RE_REJECT = 32*/
  public static RE_REJECT = 32;

  /**RE_SIGN = 33 */
  public static RA_WAITING = 33;
  /**RE_REJECT = 34*/
  public static RA_ARCHIVE = 34;

}

